import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import {
    Grid2,
    Typography,
    useMediaQuery,
    Divider,
    Button,
    Card,
    CardContent,
} from '@mui/material';

import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import Checkbox from 'core/libs/core-ui/components/Input/Checkbox';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

import PaymentPlatform from 'core/libs/types/PaymentPlatform/PaymentPlatform';

import EditIcon from 'assets/icons/edit-white.svg';

import { useTranslation } from 'core/contexts/localization';
import { useExperiencer } from 'core/contexts/experiencer';

import { useStyles } from './styles';

const ExperiencerPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { t: translate } = useTranslation();

    const {
        loading,
        experiencer,
        getExperiencer,
        setExperiencer,
        // addExperiencer,
        updateExperiencer,
    } = useExperiencer();

    const [experiencerChanged, setExperiencerChanged] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [experiencerInfo, setExperiencerInfo] = useState({});
    const [experiencerData, setExperiencerData] = useState({});
    const [notificationWhatsapp, setNotificationWhatsapp] = useState(null);
    const [payByCash, setPayByCash] = useState(false);
    const [payByTerminal, setPayByTerminal] = useState(false);
    const [payByLink, setPayByLink] = useState(false);
    const [payByTransfer, setPayByTransfer] = useState(false);

    const locationParam = document.location.href.split('/');
    const experiencerIndex = parseInt(locationParam[locationParam.length - 1]);

    const onSubmit = async (values) => {
        try {
            if (experiencerIndex === 0) {
                // const valuesClone = { ...values };
                // const newExperiencer = await addExperiencer(valuesClone);
                // history.push(`/experiencer/${newExperiencer.id}`);
                history.push(`/experiencer`);
            } else {
                const valuesClone = { ...values };
                valuesClone.notificationWhatsapp = notificationWhatsapp;
                valuesClone.payByCash = payByCash;
                valuesClone.payByTerminal = payByTerminal;
                valuesClone.payByLink = payByLink;
                valuesClone.payByTransfer = payByTransfer;
                if (experiencerChanged) {
                    await updateExperiencer(valuesClone);
                }
                setCanEdit(false);
                getExperiencer(experiencerIndex);
            }
        } catch (err) {
            toast.error(err, {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };
    
    const isPaymentPlatformChecked = (id) => {
        if(!experiencerData.acceptedPaymentPlatforms || !experiencerData.acceptedPaymentPlatforms.length) { return false; }
        return experiencerData.acceptedPaymentPlatforms.some(
            (method) => method.id === id
        );
      };
  
      const onCheckPaymentPlatform = (value, paymentId) => {
        if(!Array.isArray(experiencerData.acceptedPaymentPlatforms)) { experiencerData.acceptedPaymentPlatforms = [];}
        if (!value) {
            experiencerData.acceptedPaymentPlatforms = experiencerData.acceptedPaymentPlatforms.filter(
                    (method) =>
                        method.id !== paymentId
                );
        } else {  
          experiencerData.acceptedPaymentPlatforms.push({
                id: paymentId,
            });
        }
        setExperiencerData({...experiencerData});
      };

    useEffect(() => {
        setExperiencer({});
        if (experiencerIndex === 0) {
            // setCanEdit(true);
            history.push(`/experiencer`);
        } else {
            getExperiencer(experiencerIndex);
        }
    }, []);

    useEffect(() => {
        setExperiencerData(experiencer);
        setNotificationWhatsapp(experiencer.notificationWhatsapp || false);
        setPayByCash(experiencer.payByCash);
        setPayByTerminal(experiencer.payByTerminal);
        setPayByLink(experiencer.payByLink);
        setPayByTransfer(experiencer.payByTransfer);

    }, [experiencer]);

    return (
        (<Layout contentClassName={classes.content}>
            <Title value={'Experiencer'} />
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    // STARTS EXPERIENCER INFO
                    (<Grid2>
                        <div className={classes.formContainer}>
                            <Title value={experiencer.name} size={'l'} />
                            <Form
                                onSubmit={onSubmit}
                                initialValues={{
                                    ...experiencerData,
                                }}
                                render={({ handleSubmit, form }) => (
                                    <form id="form" onSubmit={handleSubmit}>
                                        <div>
                                            <div
                                                className={
                                                    classes.divEditButton
                                                }>
                                                <Typography
                                                    style={{
                                                        fontWeight: 'bold',
                                                        marginBottom: '10px',
                                                    }}
                                                    variant="h5"
                                                    className={classes.text}>
                                                    {translate(
                                                        'experiencer.edit_experiencer_data'
                                                    )}
                                                </Typography>
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        setCanEdit(true);
                                                    }}
                                                    style={{
                                                        height: 40,
                                                        margin: 'inherit',
                                                    }}
                                                    className={
                                                        classes.editButton
                                                    }>
                                                    <img
                                                        src={EditIcon}
                                                        width={20}
                                                    />
                                                    {isSmall ? null : (
                                                        <>&nbsp;</>
                                                    )}
                                                    {isSmall
                                                        ? null
                                                        : `${translate(
                                                              'experiencer.edit'
                                                          )}`}
                                                </Button>
                                            </div>

                                            <Divider />

                                            <div
                                                style={{
                                                    marginTop: '20px',
                                                    marginBottom: '20px',
                                                }}>
                                                <Grid2 container spacing={3}>
                                                    <Grid2 size={{xs: 12, sm: 3}}>
                                                        {experiencer.pictureURL ? (
                                                            <>
                                                                <Card
                                                                    style={{
                                                                        // minWidth: 275,
                                                                        maxWidth: 375,
                                                                    }}>
                                                                    <CardContent>
                                                                        <img
                                                                            src={`${process.env.REACT_APP_CDN_URL}${experiencer.pictureURL}`}
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                        />
                                                                    </CardContent>
                                                                </Card>
                                                                <br />
                                                            </>
                                                        ) : null}
                                                    </Grid2>
                                                    <Grid2 size={{xs: 12, sm: 9}}>
                                                      <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                        <Grid2
                                                            container
                                                            direction={'row'}
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            alignItems={
                                                                'center'
                                                            }
                                                            spacing={3}>
                                                            <Grid2 size={{xs: 12, sm: 6}}>
                                                                <Field
                                                                    id="id"
                                                                    name="id"
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    hintText={translate(
                                                                        'experiencer.id'
                                                                    )}
                                                                    label={translate(
                                                                        'experiencer.id'
                                                                    )}
                                                                    disabled
                                                                />
                                                            </Grid2>
                                                            <Grid2 size={{xs: 12, sm: 6}}>
                                                                <Field
                                                                    name="userId"
                                                                    id="userId"
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    hintText={translate(
                                                                        'experiencer.userId'
                                                                    )}
                                                                    label={translate(
                                                                        'experiencer.userId'
                                                                    )}
                                                                    disabled={
                                                                        true
                                                                    }
                                                                />
                                                            </Grid2>
                                                        </Grid2>
                                                        <Grid2
                                                            container
                                                            direction={'row'}
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            alignItems={
                                                                'center'
                                                            }
                                                            spacing={3}>
                                                            <Grid2 size={{xs: 12, sm: 6}}>
                                                                <Field
                                                                    onFieldChange={(
                                                                        value
                                                                    ) => {
                                                                        experiencerInfo.name =
                                                                            value;
                                                                        setExperiencerInfo(
                                                                            experiencerInfo
                                                                        );
                                                                        setExperiencerChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    id="name"
                                                                    name="name"
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    hintText={translate(
                                                                        'experiencer.name'
                                                                    )}
                                                                    label={translate(
                                                                        'experiencer.name'
                                                                    )}
                                                                    disabled={
                                                                        !canEdit
                                                                    }
                                                                />
                                                            </Grid2>
                                                            <Grid2 size={{xs: 12, sm: 6}}>
                                                                <Field
                                                                    onFieldChange={(
                                                                        value
                                                                    ) => {
                                                                        experiencerInfo.email =
                                                                            value;
                                                                        setExperiencerInfo(
                                                                            experiencerInfo
                                                                        );
                                                                        setExperiencerChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    name="email"
                                                                    id="email"
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    hintText={translate(
                                                                        'experiencer.email'
                                                                    )}
                                                                    label={translate(
                                                                        'experiencer.email'
                                                                    )}
                                                                    disabled={
                                                                        !canEdit
                                                                    }
                                                                />
                                                            </Grid2>
                                                        </Grid2>
                                                        <Grid2
                                                            container
                                                            direction={'row'}
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            alignItems={
                                                                'center'
                                                            }
                                                            spacing={3}>
                                                            <Grid2 size={{xs: 12, sm: 6}}>
                                                                <Field
                                                                    onFieldChange={(
                                                                        value
                                                                    ) => {
                                                                        experiencerInfo.mobilePhone =
                                                                            value;
                                                                        setExperiencerInfo(
                                                                            experiencerInfo
                                                                        );
                                                                        setExperiencerChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    id="mobilePhone"
                                                                    name="mobilePhone"
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    hintText={translate(
                                                                        'experiencer.mobilePhone'
                                                                    )}
                                                                    label={translate(
                                                                        'experiencer.mobilePhone'
                                                                    )}
                                                                    disabled={
                                                                        !canEdit
                                                                    }
                                                                />
                                                            </Grid2>
                                                            <Grid2 size={{xs: 12, sm: 6}}>
                                                                <Field
                                                                    onFieldChange={(
                                                                        value
                                                                    ) => {
                                                                        experiencerInfo.addressStreet =
                                                                            value;
                                                                        setExperiencerInfo(
                                                                            experiencerInfo
                                                                        );
                                                                        setExperiencerChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    id="addressStreet"
                                                                    name="addressStreet"
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    hintText={translate(
                                                                        'experiencer.addressStreet'
                                                                    )}
                                                                    label={translate(
                                                                        'experiencer.addressStreet'
                                                                    )}
                                                                    disabled={
                                                                        !canEdit
                                                                    }
                                                                />
                                                            </Grid2>
                                                        </Grid2>
                                                        <Grid2
                                                            container
                                                            direction={'row'}
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            alignItems={
                                                                'center'
                                                            }
                                                            spacing={3}>
                                                            <Grid2 size={{xs: 12, sm: 6}}>
                                                                <Field
                                                                    onFieldChange={(
                                                                        value
                                                                    ) => {
                                                                        experiencerInfo.addressCity =
                                                                            value;
                                                                        setExperiencerInfo(
                                                                            experiencerInfo
                                                                        );
                                                                        setExperiencerChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    id="addressCity"
                                                                    name="addressCity"
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    hintText={translate(
                                                                        'experiencer.addressCity'
                                                                    )}
                                                                    label={translate(
                                                                        'experiencer.addressCity'
                                                                    )}
                                                                    disabled={
                                                                        !canEdit
                                                                    }
                                                                />
                                                            </Grid2>
                                                            <Grid2 size={{xs: 12, sm: 6}}>
                                                                <Field
                                                                    onFieldChange={(
                                                                        value
                                                                    ) => {
                                                                        experiencerInfo.addressCountry =
                                                                            value;
                                                                        setExperiencerInfo(
                                                                            experiencerInfo
                                                                        );
                                                                        setExperiencerChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    id="addressCountry"
                                                                    name="addressCountry"
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    hintText={translate(
                                                                        'experiencer.addressCountry'
                                                                    )}
                                                                    label={translate(
                                                                        'experiencer.addressCountry'
                                                                    )}
                                                                    disabled={
                                                                        !canEdit
                                                                    }
                                                                />
                                                            </Grid2>
                                                        </Grid2>
                                                        <Grid2
                                                            container
                                                            direction={'row'}
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            alignItems={
                                                                'center'
                                                            }
                                                            spacing={3}>
                                                            <Grid2 size={{xs: 12, sm: 6}}>
                                                                <Field
                                                                    style={{
                                                                        paddingTop:
                                                                            '0',
                                                                    }}
                                                                    disabled={
                                                                        !canEdit
                                                                    }
                                                                    checked={
                                                                        notificationWhatsapp
                                                                    }
                                                                    onChange={(
                                                                        value
                                                                    ) => {
                                                                        setNotificationWhatsapp(
                                                                            value
                                                                        );
                                                                        setExperiencerChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    name="notificationWhatsapp"
                                                                    component={
                                                                        Checkbox
                                                                    }
                                                                    labelComponent={translate(
                                                                        'experiencer.notificationWhatsapp'
                                                                    )}
                                                                />
                                                            </Grid2>
                                                            <Grid2 size={{xs: 12, sm: 6}}>
                                                                <Field
                                                                    id="createdOn"
                                                                    name="createdOn"
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    hintText={translate(
                                                                        'experiencer.createdOn'
                                                                    )}
                                                                    label={translate(
                                                                        'experiencer.createdOn'
                                                                    )}
                                                                    disabled
                                                                />
                                                            </Grid2>
                                                        </Grid2> 
                                                        </div>
                                                        <Grid2
                                                            container
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            alignItems={
                                                                'center'
                                                            }
                                                            spacing={3}>
<Grid2 size={{xs: 12, sm: 4}}>
<Field
                                                            style={{
                                                                paddingTop: '0',
                                                            }}
                                                            disabled={!canEdit}
                                                            checked={isPaymentPlatformChecked(PaymentPlatform.IDS.cash)}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                onCheckPaymentPlatform(value, PaymentPlatform.IDS.cash)
                                                                setExperiencerChanged( true );
                                                            }}
                                                            name="payByCash"
                                                            component={Checkbox}
                                                            labelComponent="Accept Cash"
                                                        />
                                                        <Field
                                                            style={{
                                                                paddingTop: '0',
                                                            }}
                                                            disabled={!canEdit}
                                                            checked={isPaymentPlatformChecked(PaymentPlatform.IDS.terminal)}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                              onCheckPaymentPlatform(value, PaymentPlatform.IDS.terminal)
                                                                setExperiencerChanged( true );
                                                            }}
                                                            name="payByTerminal"
                                                            component={Checkbox}
                                                            labelComponent="Accept Terminal Payments"
                                                        />
                                                        <Field
                                                            style={{
                                                                paddingTop: '0',
                                                            }}
                                                            disabled={!canEdit}
                                                            errorMessage={translate(
                                                                'form.required'
                                                            )}
                                                            checked={isPaymentPlatformChecked(PaymentPlatform.IDS.link)}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                              onCheckPaymentPlatform(value, PaymentPlatform.IDS.link)
                                                                setExperiencerChanged( true );
                                                            }}
                                                            name="payByLink"
                                                            component={Checkbox}
                                                            labelComponent="Accept Link Payments"
                                                        />
                                                        <Field
                                                            style={{
                                                                paddingTop: '0',
                                                            }}
                                                            disabled={!canEdit}
                                                            errorMessage={translate(
                                                                'form.required'
                                                            )}
                                                            checked={isPaymentPlatformChecked(PaymentPlatform.IDS.transfer)}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                              onCheckPaymentPlatform(value, PaymentPlatform.IDS.transfer)
                                                                setExperiencerChanged( true );
                                                            }}
                                                            name="payByTransfer"
                                                            component={Checkbox}
                                                            labelComponent="Accept Transfer Payments"
                                                        />
                                                                <Field
                                                            style={{
                                                                paddingTop: '0',
                                                            }}
                                                            disabled={!canEdit}
                                                            errorMessage={translate(
                                                                'form.required'
                                                            )}
                                                            checked={isPaymentPlatformChecked(PaymentPlatform.IDS.stripe)}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                onCheckPaymentPlatform(value, PaymentPlatform.IDS.stripe)
                                                                setExperiencerChanged( true );
                                                            }}
                                                            name="payByCard"
                                                            component={Checkbox}
                                                            labelComponent="Accept Card Payments"
                                                        />
                                                            </Grid2>
                                                            <Grid2 size={{xs: 12, sm: 8}}>
                                                                <Field
                                                                    style={{
                                                                        // marginBottom:
                                                                        //     '2em',
                                                                        width: '99%',
                                                                    }}
                                                                    onFieldChange={(
                                                                        value
                                                                    ) => {
                                                                        experiencerInfo.description =
                                                                            value;
                                                                        setExperiencerInfo(
                                                                            experiencerInfo
                                                                        );
                                                                        setExperiencerChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    id="description"
                                                                    name="description"
                                                                    component={
                                                                        'textarea'
                                                                    }
                                                                    label={translate(
                                                                        'experiencer.description'
                                                                    )}
                                                                    disabled={
                                                                        !canEdit
                                                                    }
                                                                    multiline
                                                                    rows={8}
                                                                    // initialCharacters={contract?.description?.length || 500}
                                                                />
                                                            </Grid2>
                                                        </Grid2>
                                                    </Grid2>
                                                </Grid2>
                                            </div>
                                        </div>

                                        <Grid2
                                            container
                                            justifyContent="flex-end"
                                            alignContent="flex-end">
                                            <Grid2
                                                container
                                                justifyContent={'space-between'}
                                                style={{ width: 'auto' }}>
                                                <Button
                                                    className="rounded mr-3 height-40 w-100px"
                                                    disabled={!canEdit}
                                                    type="button"
                                                    onClick={(e) => {
                                                        setCanEdit(false);
                                                        return form.reset(e);
                                                    }}>
                                                    {translate('btn_cancel')}
                                                </Button>

                                                <Button
                                                    onClick={(e) => {
                                                        setCanEdit(false);
                                                        return handleSubmit(e);
                                                    }}
                                                    className="rounded height-40 w-100px triper-button bg-green-300"
                                                    disabled={!canEdit}
                                                    type="submit">
                                                    {translate('btn_submit')}
                                                </Button>
                                            </Grid2>
                                        </Grid2>
                                    </form>
                                )}
                            />
                        </div>
                    </Grid2>)
                    // ENDS EXPERIENCER INFO
                )}
            </div>
        </Layout>)
    );
};

ExperiencerPage.displayName = 'ExperiencerPage';

export default ExperiencerPage;
