import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(2),
    },
    text: {
        marginTop: theme.spacing(2),
    },
    marginLeft: {
        marginLeft: theme.spacing(2),
    },
    formContainer: {
        padding: '10px',
    },
    formTitle: {
        marginBottom: theme.spacing(2),
    },
    formFormControl: {
        marginBottom: theme.spacing(3),
    },
    divEditButton: {
        display: 'flex !important',
        justifyContent: 'space-between !important',
        gap: '30px !important',
    },
    editButton: {
        cursor: 'pointer !important',
        border: 'none !important',
        backgroundColor: '#71ba6c !important',

        color: '#ffffff !important',
        lineHeight: '40px !important',
        height: '40px !important',
        textAlign: 'center !important',
        fontSize: '14px !important',
        borderRadius: '4px !important',
        border: 'none !important',
        padding: '0 20px !important',
        cursor: 'pointer !important',
        display: 'flex',
        alignItems: 'center',
        gridGap: '15px',
        gap: '15px',
    },

    SaveButton: {
        float: 'right',
        paddingTop: '9px',
        marginLeft: '14px',
        backgroundColor: '#71ba6c !important',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontWeight: '700',
        width: '99px',
        lineHeight: '1.75',
        '&:hover': {
            background: '#1d539d',
        },
    },
    clearButton: {
        background: '#C4C4C4',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontWeight: '700',
        lineHeight: '1.75',
        '&:hover': {
            background: 'grey',
        },
    },
    hidden: {
        display: 'none',
    },
    active: {
        display: 'flex',
    },
    tableButton: {
      padding: '4px',
      borderRadius: '50%',
      transition: 'background 0.3s ease',
      '&:hover': {
        background: 'lightgrey'
      },
      '&:hover:active': {
        background: '#e8e8e8'
      }
    },
    duplicateBtn: {
      display: 'flex',
      gap: '4px',
      color: '#000000 !important',
      lineHeight: '40px !important',
      height: '40px !important',
      
    },
    titleWithBtnRow: {
      display: 'flex', justifyContent: 'space-between', 
      alignItems: 'center'
    }
}));
