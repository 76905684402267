import Layout from "components/Layout"
import { useStyles } from './styles';
import { useMediaQuery, Grid2, Button } from "@mui/material";
import Title from "core/libs/core-ui/components/Title";
import BasicTable from "core/libs/core-ui/components/Table";
import { useDiscount } from "core/contexts/discounts";
import { useEffect, useState } from "react";
import CircularIndeterminate from "core/libs/core-ui/components/Progress";
import { DateFormattedUtils } from "core/libs/core-ui/utils/dateTimeFormatted";
import { useHistory } from "react-router-dom";
import PaginationControlled from "core/libs/core-ui/components/Pagination";

const DiscountsPage = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const classes = useStyles()
  const { getDiscounts, discounts, loading, total } = useDiscount();

  const [page, setPage] = useState(1);
  const MAX_PAGE = 20;

  const history = useHistory()

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  
  useEffect(() => {
    getDiscounts({
    pager: {
        page,
        pageSize: MAX_PAGE
      }
    });
  }, []);

  useEffect(() => {
    getDiscounts({
    pager: {
        page,
        pageSize: MAX_PAGE
      }
    });
  }, [page]);


  const columns = [
    { align: 'left', val: 'id', label: "Id", column: "ci_id", },
    { align: 'center', val: 'code', label: "Code", column: "ci_code", },
    { val: 'additional', label: "Additional", column: "ci_additional", },
    { val: 'discountRate', label: "Discount Rate", column: "ci_discount_rate", },
    { val: 'startString', label: "Start", column: "ci_start", },
    { val: 'endString', label: "End", column: "ci_end", },
    { val: 'createdOnString', label: "Created On", column: "ci_created_on", },
    { val: 'createdBy', label: "Created By", column: "ci_created_by", },
    { val: 'updatedOnString', label: "Updated On", column: "ci_updated_On", },
    { val: 'updatedBy', label: "Updated By", column: "ci_updated_by", },
    { align: 'center', val: 'status', label: "Status", column: "ci_status", },
  ];

  const mobileColumns = [
    { align: 'left', val: 'id', label: 'Id', },
    { align: 'center', val: 'code', label: "Code", column: "ci_code", },
    { val: 'additional', label: "Additional", column: "ci_additional", },
    { val: 'createdBy', label: "Created By", column: "ci_created_by", },
    { align: 'center', val: 'status', label: 'Status', column: "ci_status", },
  ];


  const prettifyDiscount = (discounts = []) => {
    return discounts.map(discount => {
      return {
        createdOnString: DateFormattedUtils(discount.createdOn),
        updatedOnString: DateFormattedUtils(discount.updatedOn),
        startString: discount.start ? DateFormattedUtils(discount.start) : "",
        endString: discount.end ? DateFormattedUtils(discount.end) : "",
        ...discount
      }
    })
  };

  const onClick = (event, id) => {
    history.push(`/discount/${id}`)
  };


  return (
    <Layout contentClassName={classes.content}>
      <Title value={'Discounts'} />
      <div style={{ marginBottom: "25px" }}>
        <Grid2 container
          direction="row"
          justifyContent="space-between"
          alignItems="center">

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push('discount/0')
            }}
          >ADD</Button>

        </Grid2>

      </div>
      <div>
        {loading ? (
          <CircularIndeterminate />
        ) : (
          <>
            <BasicTable columns={!isSmall ? columns : mobileColumns} values={prettifyDiscount(discounts)} onClick={onClick} />
            <br />
            <Grid2 container alignItems='flex-end'>
              <Grid2 size={{xs: 1, sm: 7}}></Grid2>
              <Grid2 size={{xs: 1, sm: 7}}>
                {
                  total/MAX_PAGE < 1 ? null:
                (<PaginationControlled count={(Math.ceil(total/MAX_PAGE))} page={page} onChange={handlePageChange} />)
                }
              </Grid2>
            </Grid2>
          </>
        )
        }
      </div>
    </Layout>
  )
};

DiscountsPage.displayName = 'Discountpage';

export default DiscountsPage;
