import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import {
    Typography,
    Grid2,
    useMediaQuery,
    Divider,
    Button,
    Card,
    CardContent,
    InputLabel,
    TextField,
} from '@mui/material';
import { toast } from 'react-toastify';

import EditIcon from 'assets/icons/edit-white.svg';
import DuplicateIcon from 'assets/icons/duplicate.svg';
import Layout from 'components/Layout';

import Title from 'core/libs/core-ui/components/Title';
import BasicTable from 'core/libs/core-ui/components/Table';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import Dialog from 'core/libs/core-ui/components/Dialog';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

import { useTranslation } from 'core/contexts/localization';
import { useContract } from 'core/contexts/contract';
import { ContractService } from 'core/api/services/contract';

import { useStyles } from './styles';
import { Delete, Edit } from '@mui/icons-material';
import CheckboxComponent from 'core/libs/core-ui/components/Input/Checkbox';

const ContractPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { t: translate } = useTranslation();

    const {
        getContract,
        setContract,
        contract,
        contractExperiences,
        contractBundles,
        loading,
        updateContract,
        addContract,
        addContractExperiences,
        deleteContractExperiences,
        totalExperiences,
        getContractExperience,
        updateContractExperience,
        updateContractBundles,
        deleteContractBundles,
        totalBundles,
        getContractBundle,
    } = useContract();

    const [contractChanged, setContractChanged] = useState(false);
    const [contractExpChanged, setContractExpChanged] = useState(false);
    const [contractBundleChanged, setContractBundleChanged] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [contractInfo, setContractInfo] = useState({});
    const [contractData, setContractData] = useState({});
    const [file, setFile] = useState();
    const [expToDelete, setExpToDelete] = useState(null);
    const [bundleToDelete, setBundleToDelete] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [openDuplicate, setOpenDuplicate] = useState(false);
    const [expToEdit, setExpToEdit] = useState(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [openBundle, setOpenBundle] = useState(false);
    const [page, setPage] = useState(1);
    const [pageBundle, setPageBundle] = useState(1);

    const MAX_PAGE = 10;

    const locationParam = document.location.href.split('/');
    const contractIndex = locationParam[locationParam.length - 1];

    const prettifyContractExperiences = (contractExp = []) => {
        return contractExp.map((experience) => ({
            ...experience,
            markupId:
                experience.markupId === null ? '' : `${experience.markupId}`,
            discountId:
                experience.discountId === null
                    ? ''
                    : `${experience.discountId}`,
            edit: (
                <Edit
                    onClick={() => {
                        setExpToEdit(experience);
                        setOpenEdit(true);
                    }}
                    className={classes.tableButton}
                />
            ),
            delete: (
                <Delete
                    onClick={() => {
                        setExpToDelete(experience.id);
                        setOpenDelete(true);
                    }}
                    className={classes.tableButton}
                />
            ),
        }));
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handlePageBundleChange = (event, value) => {
        setPageBundle(value);
    };

    const onSubmitQR = async () => {
        try {
            await ContractService.getQRCode(contractData.id);
            setCanEdit(false);
            getContract(contractIndex);
            getContractExperience(contractIndex, {
                pager: {
                    page,
                    pageSize: MAX_PAGE,
                },
            });
            getContractBundle(contractIndex, {
                pager: {
                    page: pageBundle,
                    pageSize: MAX_PAGE,
                },
            });
        } catch (err) {
            toast.error(err, {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    const onSubmitContractImage = async (values) => {
        if (file) {
            // await ExperiencerService.updateAvatar(info?.experiencer?.id, file);
            try {
                await ContractService.uploadContractPicture(
                    contractData.id,
                    file
                );
                setCanEdit(false);
                setFile(null);
                getContract(contractIndex);
                getContractExperience(contractIndex, {
                    pager: {
                        page,
                        pageSize: MAX_PAGE,
                    },
                });
                getContractBundle(contractIndex, {
                    pager: {
                        page: pageBundle,
                        pageSize: MAX_PAGE,
                    },
                });
            } catch (err) {
                toast.error(err, {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
        }
    };

    const onSubmitDeleteExperiences = async () => {
        if (contractData.id && expToDelete) {
            try {
                await deleteContractExperiences(contractData.id, expToDelete);
                setCanEdit(false);
                setExpToDelete(null);
                setOpenDelete(false);
                getContract(contractIndex);
                getContractExperience(contractIndex, {
                    pager: {
                        page,
                        pageSize: MAX_PAGE,
                    },
                });
            } catch (err) {
                toast.error(err, {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
        }
    };

    const onSubmitDeleteBundles = async () => {
        if (contractData.id && bundleToDelete) {
            try {
                await deleteContractBundles(contractData.id, bundleToDelete);
                setCanEdit(false);
                setBundleToDelete(null);
                setOpenBundle(false);
                getContract(contractIndex);
                getContractBundle(contractIndex, {
                    pager: {
                        page: pageBundle,
                        pageSize: MAX_PAGE,
                    },
                });
            } catch (err) {
                toast.error(err, {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
        }
    };

    const onSubmitExperiences = async (values) => {
        const valuesClon = { ...values };
        if (
            contractData.id &&
            contractExpChanged &&
            valuesClon.experiences.length > 0
        ) {
            try {
                const experiences = valuesClon.experiences.split(',');
                await addContractExperiences(contractData.id, {
                    experiences,
                });
                setCanEdit(false);
                getContract(contractIndex);
                getContractExperience(contractIndex, {
                    pager: {
                        page,
                        pageSize: MAX_PAGE,
                    },
                });
            } catch (err) {
                toast.error(err, {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
        }
    };

    const onSubmitBundles = async (values) => {
        const valuesClon = { ...values };
        if (
            contractData.id &&
            contractBundleChanged &&
            valuesClon.bundle.length > 0
        ) {
            try {
                const bundle = valuesClon.bundle.split(',');
                await updateContractBundles(contractData.id, { bundle });
                setCanEdit(false);
                getContract(contractIndex);
                getContractBundle(contractIndex, {
                    pager: {
                        page: pageBundle,
                        pageSize: MAX_PAGE,
                    },
                });
            } catch (err) {
                toast.error(err, {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
        }
    };

    const onSubmit = async (values) => {
        try {
            if (contractIndex === '0') {
                const valuesClon = { ...values };
                delete valuesClon.code;
                delete valuesClon.hasQR;
                delete valuesClon.qrURL;
                delete valuesClon.link;

                const newContract = await addContract(valuesClon);
                history.push(`/contract/${newContract.id}`);

                // const userInfoSync = await getUserInfoSync();
                // userInfoSync.experiencer.email = userInfoSync?.email;
                // setUserInfo(userInfoSync?.experiencer);
                // setCountryCode(userInfoSync?.experiencer?.mobilePhoneCountryCode);
                // setCanEdit(false);
                // getContract(newContract.id);
            } else {
                // values = { experiencer: userInfo };
                const valuesClon = { ...values };

                // delete valuesClon.experiencer.pictureURL;
                delete valuesClon.code;
                delete valuesClon.hasQR;
                delete valuesClon.qrURL;
                delete valuesClon.link;
                
                if (!valuesClon.discountId) {
                    valuesClon.discountId = null;
                }
                if (!valuesClon.markupId) {
                    valuesClon.markupId = null;
                }

                // if (file)
                //   await ExperiencerService.updateAvatar(info?.experiencer?.id, file);

                // else console.log('No file was submitted');
                // setUserInfo({ ...userInfo, ...values });
                // setSaved(true);
                // console.log('VALUES====', valuesClon, 'UPDATED==', profileChanged);
                if (contractChanged) {
                    await updateContract(valuesClon);
                }
                // const userInfoSync = await getUserInfoSync();
                // userInfoSync.experiencer.email = userInfoSync?.email;
                // setUserInfo(userInfoSync?.experiencer);
                // setCountryCode(userInfoSync?.experiencer?.mobilePhoneCountryCode);
                setCanEdit(false);
                getContract(contractIndex);
                getContractExperience(contractIndex, {
                    pager: {
                        page,
                        pageSize: MAX_PAGE,
                    }, 
                });
                getContractBundle(contractIndex, {
                    pager: {
                        page: pageBundle,
                        pageSize: MAX_PAGE,
                    },
                });
                // setOpenSnackBar(true);
                // setContractData(contractInfo);
            }
        } catch (err) {
            toast.error(err, {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    useEffect(() => {
        setContract({});
        if (contractIndex == 0) {
            setCanEdit(true);
        } else {
            getContract(contractIndex);
            getContractExperience(contractIndex, {
                pager: {
                    page,
                    pageSize: MAX_PAGE,
                },
            });
            getContractBundle(contractIndex, {
                pager: {
                    page: pageBundle,
                    pageSize: MAX_PAGE,
                },
            });
        }
    }, [contractIndex]);

    useEffect(() => {
        setContractData(contract);
        if (contractIndex == 0) return;
        getContractExperience(contractIndex, {
            pager: {
                page,
                pageSize: MAX_PAGE,
            },
        });
        getContractBundle(contractIndex, {
            pager: {
                page: pageBundle,
                pageSize: MAX_PAGE,
            },
        });
    }, [contract]);

    useEffect(() => {
        if (contractIndex == 0) {
            return;
        }
        getContractExperience(contractIndex, {
            pager: {
                page,
                pageSize: MAX_PAGE,
            },
        });
    }, [page]);

    useEffect(() => {
        if (contractIndex == 0) {
            return;
        }
        getContractBundle(contractIndex, {
            pager: {
                page: pageBundle,
                pageSize: MAX_PAGE,
            },
        });
    }, [pageBundle]);

    const [expChanged, setExpChanged] = useState(false);

    const onSubmitUpdateContractExperience = async (values) => {
        if (expChanged) {
            await updateContractExperience(
                contractIndex,
                expToEdit.contractExpId,
                values
            );
            setOpenEdit(false);
            getContract(contractIndex);
            setExpChanged(false);
            getContractExperience(contractIndex, {
                pager: {
                    page,
                    pageSize: MAX_PAGE,
                },
            });
        }
    };

    const renderEditForm = () => {
        return (
            <Form
                onSubmit={onSubmitUpdateContractExperience}
                initialValues={expToEdit}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid2 container direction="column">
                            <Grid2
                                container
                                direction="row"
                                style={{
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                <Grid2>
                                    <h3 style={{ fontWeight: '700' }}>
                                        {expToEdit?.name || ''}
                                    </h3>
                                </Grid2>
                                <Grid2>
                                    <Button
                                        onClick={(e) => {
                                            form.reset({
                                                duration: null,
                                                currency: null,
                                                price: null,
                                            });
                                            setExpChanged(true);
                                        }}
                                        className={`${
                                            expToEdit?.duration
                                                ? classes.clearButton
                                                : classes.hidden
                                        }`}>
                                        Vaciar
                                    </Button>
                                </Grid2>
                            </Grid2>
                            <Grid2
                                style={{ width: '100%', margin: '.5rem 2px' }}>
                                <InputLabel>Markup Id:</InputLabel>
                                <Field name="markupId">
                                    {({ input }) => (
                                        <TextField
                                            {...input}
                                            variant="outlined"
                                            onChange={(e) => {
                                                setExpChanged(true);
                                                let value =
                                                    e.target.value < 0
                                                        ? 0
                                                        : e.target.value;
                                                if (value === '') value = null;
                                                // setExperience({ ...experience, duration: value });
                                                input.onChange(value);
                                            }}
                                            type="number"
                                        />
                                    )}
                                </Field>
                            </Grid2>
                            <Grid2
                                style={{ width: '100%', margin: '.5rem 2px' }}>
                                <InputLabel>Discount Id:</InputLabel>
                                <Field name="discountId">
                                    {({ input }) => (
                                        <TextField
                                            {...input}
                                            variant="outlined"
                                            onChange={(e) => {
                                                setExpChanged(true);
                                                let value =
                                                    e.target.value < 0
                                                        ? 0
                                                        : e.target.value;
                                                if (value === '') value = null;
                                                // setExperience({ ...experience, price: value });
                                                input.onChange(value);
                                            }}
                                            type="number"
                                        />
                                    )}
                                </Field>
                            </Grid2>

                            <Grid2
                                container
                                direction="row"
                                style={{
                                    height: '50px',
                                    marginTop: '10px',
                                }}>
                                <Grid2>
                                    <Button
                                        onClick={(e) => {
                                            setOpenEdit(false);
                                            setExpChanged(false);
                                            return form.reset(e);
                                        }}
                                        className={classes.CancelButton}>
                                        Cancelar
                                    </Button>
                                </Grid2>
                                <Grid2>
                                    <Button
                                        onClick={(e) => {
                                            //   setExperience({})
                                            return handleSubmit(e);
                                        }}
                                        disabled={!expChanged}
                                        className={classes.SaveButton}>
                                        Guardar
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </form>
                )}
            />
        );
    };
    
    const onDuplicateSubmit = async () => {
        
       if(contractIndex === '0') {
          return false
       }
      
        const valuesClon = { ...contract };
        if(valuesClon.name.slice(-5) !== '-copy'){
          valuesClon.name += ' -copy';
        }

        delete valuesClon.id;
        delete valuesClon.code;
        // delete valuesClon.hasQR;
        delete valuesClon.qrURL;
        delete valuesClon.link;
        delete valuesClon.updatedBy;
        delete valuesClon.updatedOn;
        delete valuesClon.createdBy;
        delete valuesClon.createdOn;
        delete valuesClon.experiences;
        try {
           const newContract = await addContract({ ...valuesClon });
          
            const experiences = contractExperiences.map((ce) =>
                ce.id.toString()
            );

            await addContractExperiences(newContract.id, {
                experiences,
            });

            const bundle = valuesClon.bundle.bundle.map((bundle) =>
                bundle.id.toString()
            );

            await updateContractBundles(newContract.id, { bundle });

            setOpenDuplicate(false);
            toast.success(`Contract duplicated!`, {
              position: 'top-right',
              theme: 'colored',
            });
            history.push(`/contract/${newContract.id}`);
          } catch (error) {
            toast.error(error, {
              position: 'top-right',
              theme: 'colored',
            });
            setOpenDuplicate(false);
        }
    };

    return (
        (<Layout contentClassName={classes.content}>
            <Title value={'Contracts'} />
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    // Starts CONTRACT INFO
                    (<Grid2>
                        <div className={classes.formContainer}>
                            <div className={classes.titleWithBtnRow}>
                                <Title value={contract.name} size={'l'} />
                                {contractIndex !== '0' ? (
                                    <Button
                                        className={classes.duplicateBtn}
                                        variant="contained"
                                        color="tertiary"
                                        onClick={() => setOpenDuplicate(true)}>
                                        {' '}
                                        <img
                                            src={DuplicateIcon}
                                            width={20}
                                            alt="Duplicate"
                                        />
                                        {isSmall
                                            ? null
                                            : `${translate(
                                                  'contract.duplicate'
                                              )}`}
                                    </Button>
                                ) : null}
                            </div>
                            <Title
                                size={'s'}
                                value={
                                    <a href={contract.link}>{contract.link}</a>
                                }
                            />
                            <Grid2 container>
                                {contract.qrURL ? (
                                    <Grid2 size={{xs: 12, sm: 6}}>
                                        <Card
                                            style={{
                                                minWidth: 275,
                                                maxWidth: 375,
                                            }}>
                                            <CardContent>
                                                <img
                                                    src={`${process.env.REACT_APP_CDN_URL}${contract.qrURL}`}
                                                    style={{ width: '100%' }}
                                                />
                                            </CardContent>
                                        </Card>
                                        <Button
                                            onClick={(e) => {
                                                // setCanEdit(false);
                                                return onSubmitQR(e);
                                            }}
                                            className=""
                                            // disabled={!contractData.id || !file}
                                            type="submit">
                                            Refresh QR
                                        </Button>
                                        <br />
                                    </Grid2>
                                ) : null}
                                <Grid2 size={{xs: 12, sm: 6}}>
                                    {contract.pictureURL ? (
                                        <>
                                            <Card
                                                style={{
                                                    minWidth: 275,
                                                    maxWidth: 375,
                                                }}>
                                                <CardContent>
                                                    <img
                                                        src={`${process.env.REACT_APP_CDN_URL}${contract.pictureURL}`}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                </CardContent>
                                            </Card>
                                            <br />
                                        </>
                                    ) : null}
                                    {contract.id ? (
                                        <>
                                            <Form
                                                onSubmit={onSubmitContractImage}
                                                initialValues={{
                                                    ...file,
                                                }}
                                                render={({
                                                    handleSubmit,
                                                    form,
                                                }) => (
                                                    <form
                                                        id="form"
                                                        onSubmit={handleSubmit}>
                                                        <Button component="label">
                                                            Upload
                                                            <input
                                                                id="file"
                                                                type="file"
                                                                onChange={(
                                                                    target
                                                                ) => {
                                                                    setFile(
                                                                        target
                                                                            ?.target
                                                                            ?.files[0]
                                                                    );
                                                                }}
                                                                hidden
                                                            />
                                                        </Button>
                                                        <Button
                                                            onClick={(e) => {
                                                                setCanEdit(
                                                                    false
                                                                );
                                                                return handleSubmit(
                                                                    e
                                                                );
                                                            }}
                                                            className=""
                                                            disabled={
                                                                !contractData.id ||
                                                                !file
                                                            }
                                                            type="submit">
                                                            {translate(
                                                                'btn_add'
                                                            )}
                                                        </Button>
                                                    </form>
                                                )}
                                            />
                                        </>
                                    ) : null}
                                </Grid2>
                            </Grid2>
                            <Form
                                onSubmit={onSubmit}
                                initialValues={{
                                    ...contractData,
                                }}
                                render={({ handleSubmit, form }) => (
                                    <form id="form" onSubmit={handleSubmit}>
                                        <div>
                                            <div
                                                className={
                                                    classes.divEditButton
                                                }>
                                                <Typography
                                                    style={{
                                                        fontWeight: 'bold',
                                                        marginBottom: '10px',
                                                    }}
                                                    variant="h5"
                                                    className={classes.text}>
                                                    {translate(
                                                        'contract.edit_contract_data'
                                                    )}
                                                </Typography>
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        setCanEdit(true);
                                                    }}
                                                    style={{
                                                        height: 40,
                                                        margin: 'inherit',
                                                    }}
                                                    className={
                                                        classes.editButton
                                                    }>
                                                    <img
                                                        src={EditIcon}
                                                        width={20}
                                                        alt="Edit"
                                                    />
                                                    {isSmall ? null : (
                                                        <>&nbsp;</>
                                                    )}
                                                    {isSmall
                                                        ? null
                                                        : `${translate(
                                                              'contract.edit'
                                                          )}`}
                                                </Button>
                                            </div>

                                            <Divider />

                                            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            // onFieldChange={(value) => {
                                                            //   contractInfo.id = value;
                                                            //   setContractInfo(contractInfo);
                                                            //   setContractChanged(true);
                                                            // }}
                                                            id="id"
                                                            name="id"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.id'
                                                            )}
                                                            label={translate(
                                                                'contract.id'
                                                            )}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            // onFieldChange={(value) => {
                                                            //   contractInfo.code = value;
                                                            //   setContractInfo(contractInfo);
                                                            //   setContractChanged(true);
                                                            // }}
                                                            name="code"
                                                            id="code"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.code'
                                                            )}
                                                            label={translate(
                                                                'contract.code'
                                                            )}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.name =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="name"
                                                            name="name"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.name'
                                                            )}
                                                            label={translate(
                                                                'contract.name'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.contactEmail =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            name="contactEmail"
                                                            id="contactEmail"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.contactEmail'
                                                            )}
                                                            label={translate(
                                                                'contract.contactEmail'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.comission =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="comission"
                                                            name="comission"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.comission'
                                                            )}
                                                            label={translate(
                                                                'contract.comission'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.notificationEmail =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="notificationEmail"
                                                            name="notificationEmail"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.notificationEmail'
                                                            )}
                                                            label={translate(
                                                                'contract.notificationEmail'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{ xs: 12, md: 6 }} container>
                                                        <Grid2 size={{ xs: 12, md: 6 }}>
                                                            <Field
                                                                onFieldChange={(
                                                                    value
                                                                ) => {
                                                                    contractInfo.discountId =
                                                                        value;
                                                                    setContractInfo(
                                                                        contractInfo
                                                                    );
                                                                    setContractChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                id="discountId"
                                                                name="discountId"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'contract.discountId'
                                                                )}
                                                                label={translate(
                                                                    'contract.discountId'
                                                                )}
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            />
                                                        </Grid2>
                                                        <Grid2 size={{ xs: 12, md: 6 }}>
                                                            <Field
                                                                onFieldChange={(
                                                                    value
                                                                ) => {
                                                                    contractInfo.markupId =
                                                                        value;
                                                                    setContractInfo(
                                                                        contractInfo
                                                                    );
                                                                    setContractChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                id="markupId"
                                                                name="markupId"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'contract.markupId'
                                                                )}
                                                                label={translate(
                                                                    'contract.markupId'
                                                                )}
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            />
                                                        </Grid2>
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.location =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="location"
                                                            name="location"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.location'
                                                            )}
                                                            label={translate(
                                                                'contract.location'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.createdBy =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="createdBy"
                                                            name="createdBy"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.createdBy'
                                                            )}
                                                            label={translate(
                                                                'contract.createdBy'
                                                            )}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.createdOn =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="createdOn"
                                                            name="createdOn"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.createdOn'
                                                            )}
                                                            label={translate(
                                                                'contract.createdOn'
                                                            )}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}
                                                    rowSpacing={9}>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.updatedBy =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="updatedBy"
                                                            name="updatedBy"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.updatedBy'
                                                            )}
                                                            label={translate(
                                                                'contract.updatedBy'
                                                            )}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.updatedOn =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="updatedOn"
                                                            name="updatedOn"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.updatedOn'
                                                            )}
                                                            label={translate(
                                                                'contract.updatedOn'
                                                            )}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.status =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="status"
                                                            name="status"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.status'
                                                            )}
                                                            label={translate(
                                                                'contract.status'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.cancelReason =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="cancelReason"
                                                            name="cancelReason"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.cancelReason'
                                                            )}
                                                            label={translate(
                                                                'contract.cancelReason'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.hostId =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="hostId"
                                                            name="hostId"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.hostId'
                                                            )}
                                                            label={translate(
                                                                'contract.hostId'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.experiencerId =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="experiencerId"
                                                            name="experiencerId"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'contract.experiencerId'
                                                            )}
                                                            label={translate(
                                                                'contract.experiencerId'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                         id="isQR"
                                                         name="isQR"
                                                         component={
                                                             CheckboxComponent
                                                         }
                                                         onFieldChange={(
                                                            value
                                                        ) => {
                                                            contractInfo.isQR =
                                                                value;
                                                            setContractInfo(
                                                                contractInfo
                                                            );
                                                            setContractChanged(
                                                                true
                                                            );
                                                        }}
                                                         hintText={translate(
                                                            'contract.isQR'
                                                        )}
                                                        label={translate(
                                                            'contract.isQR'
                                                        )}
                                                        disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, md: 6 }}></Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{ xs: 12, md: 6 }}>
                                                        <Field
                                                            style={{
                                                                marginBottom:
                                                                    '2em',
                                                                width: '99%',
                                                            }}
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                contractInfo.description =
                                                                    value;
                                                                setContractInfo(
                                                                    contractInfo
                                                                );
                                                                setContractChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="description"
                                                            name="description"
                                                            component={
                                                                'textarea'
                                                            }
                                                            label={translate(
                                                                'contract.description'
                                                            )}
                                                            disabled={!canEdit}
                                                            multiline
                                                            rows={4}
                                                            // initialCharacters={contract?.description?.length || 500}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </div>
                                        </div>

                                        <Grid2
                                            container
                                            justifyContent="flex-end"
                                            alignContent="flex-end">
                                            <Grid2
                                                container
                                                justifyContent={'space-between'}
                                                style={{ width: 'auto' }}>
                                                <Button
                                                    className="rounded mr-3 height-40 w-100px"
                                                    disabled={!canEdit}
                                                    type="button"
                                                    onClick={(e) => {
                                                        setCanEdit(false);
                                                        return form.reset(e);
                                                    }}>
                                                    {translate('btn_cancel')}
                                                </Button>

                                                <Button
                                                    onClick={(e) => {
                                                        setCanEdit(false);
                                                        return handleSubmit(e);
                                                    }}
                                                    className="rounded height-40 w-100px triper-button bg-green-300"
                                                    disabled={!canEdit}
                                                    type="submit">
                                                    {translate('btn_submit')}
                                                </Button>
                                            </Grid2>
                                        </Grid2>
                                    </form>
                                )}
                            />
                            <Grid2 container spacing={2}>
                                <Grid2 size={{ xs: 12, md: 6 }}>
                                    {contract.id ? (
                                        <>
                                            <Form
                                                onSubmit={onSubmitBundles}
                                                initialValues={{
                                                    ...contractBundles.bundle,
                                                }}
                                                render={({
                                                    handleSubmit,
                                                    form,
                                                }) => (
                                                    <form
                                                        id="form"
                                                        onSubmit={handleSubmit}>
                                                        <Title
                                                            size={'s'}
                                                            value={translate(
                                                                'contract.bundles'
                                                            )}
                                                        />
                                                        <Grid2
                                                            container
                                                            spacing={1}>
                                                            <Grid2 size={{ xs: 12, md: 6 }}>
                                                                <Field
                                                                    onFieldChange={(
                                                                        value
                                                                    ) => {
                                                                        contractInfo.bundle =
                                                                            value;
                                                                        setContractInfo(
                                                                            contractInfo
                                                                        );
                                                                        setContractBundleChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    id="bundle"
                                                                    name="bundle"
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    hintText={translate(
                                                                        'contract.bundlesHint'
                                                                    )}
                                                                    label={translate(
                                                                        'contract.bundlesHint'
                                                                    )}
                                                                />
                                                            </Grid2>
                                                            <Grid2 size={{ xs: 12, md: 6 }}>
                                                                <Button
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        // setCanEdit(false);
                                                                        return handleSubmit(
                                                                            e
                                                                        );
                                                                    }}
                                                                    className=""
                                                                    disabled={
                                                                        !contractData.id
                                                                    }
                                                                    type="submit">
                                                                    {translate(
                                                                        'btn_submit'
                                                                    )}
                                                                </Button>
                                                            </Grid2>
                                                        </Grid2>
                                                    </form>
                                                )}
                                            />
                                            <br />
                                            <BasicTable
                                                columns={[
                                                    {
                                                        align: 'left',
                                                        val: 'id',
                                                        label: 'Id',
                                                    },
                                                    {
                                                        val: 'name',
                                                        label: 'Name',
                                                    },
                                                    {
                                                        val: 'ownerId',
                                                        label: 'Tour Operator',
                                                    },
                                                    {
                                                        val: 'status',
                                                        label: 'Status',
                                                    },
                                                ]}
                                                values={contractBundles}
                                                onClick={(event, id) => {
                                                    setBundleToDelete(id);
                                                    setOpenBundle(true);
                                                }}
                                            />
                                            <br />
                                            <Grid2
                                                container
                                                alignItems="flex-end">
                                                <Grid2 size={{ xs: 2, sm: 4 }}></Grid2>
                                                <Grid2 size={{ xs: 10, sm: 8 }}>
                                                    {totalBundles / MAX_PAGE <
                                                    1 ? null : (
                                                        <PaginationControlled
                                                            count={Math.ceil(
                                                                totalBundles /
                                                                    MAX_PAGE
                                                            )}
                                                            page={pageBundle}
                                                            onChange={
                                                                handlePageBundleChange
                                                            }
                                                        />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                            <Dialog
                                                open={openBundle}
                                                dialogTitle={'Attention!'}
                                                dialogContent={`Are you sure you want to remove the bundle ${bundleToDelete}`}
                                                handleClose={() => {
                                                    setBundleToDelete(null);
                                                    setOpenBundle(false);
                                                }}
                                                handleSubmit={
                                                    onSubmitDeleteBundles
                                                }
                                            />
                                        </>
                                    ) : null}
                                </Grid2>
                                <Grid2 size={{ xs: 12, md: 6 }}>
                                    {contract.id ? (
                                        <>
                                            <Form
                                                onSubmit={onSubmitExperiences}
                                                initialValues={{
                                                    ...contractExperiences.experiences,
                                                }}
                                                render={({
                                                    handleSubmit,
                                                    form,
                                                }) => (
                                                    <form
                                                        id="form"
                                                        onSubmit={handleSubmit}>
                                                        <Title
                                                            size={'s'}
                                                            value={translate(
                                                                'contract.experiences'
                                                            )}
                                                        />
                                                        <Grid2
                                                            container
                                                            spacing={1}>
                                                            <Grid2 size={{xs: 12, sm: 10}}>
                                                                <Field
                                                                    onFieldChange={(
                                                                        value
                                                                    ) => {
                                                                        contractInfo.experiences =
                                                                            value;
                                                                        setContractInfo(
                                                                            contractInfo
                                                                        );
                                                                        setContractExpChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    id="experiences"
                                                                    name="experiences"
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    hintText={translate(
                                                                        'contract.experiencesHint'
                                                                    )}
                                                                    label={translate(
                                                                        'contract.experiencesHint'
                                                                    )}
                                                                    // disabled={!canEdit}
                                                                />
                                                            </Grid2>
                                                            <Grid2 size={{ xs: 2, sm: 1 }}>
                                                                <Button
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        // setCanEdit(false);
                                                                        return handleSubmit(
                                                                            e
                                                                        );
                                                                    }}
                                                                    className=""
                                                                    disabled={
                                                                        !contractData.id
                                                                    }
                                                                    type="submit">
                                                                    {translate(
                                                                        'btn_submit'
                                                                    )}
                                                                </Button>
                                                            </Grid2>
                                                        </Grid2>
                                                    </form>
                                                )}
                                            />
                                            <br />
                                            <BasicTable
                                                columns={[
                                                    {
                                                        align: 'left',
                                                        val: 'id',
                                                        label: 'Id',
                                                    },
                                                    {
                                                        val: 'name',
                                                        label: 'Name',
                                                    },
                                                    {
                                                        val: 'experiencerName',
                                                        label: 'Tour Operator',
                                                    },
                                                    {
                                                        val: 'markupId',
                                                        label: 'Markup Id',
                                                    },
                                                    {
                                                        val: 'discountId',
                                                        label: 'Discount Id',
                                                    },
                                                    {
                                                        val: 'edit',
                                                        label: ' ',
                                                        style: { width: '1px' },
                                                    },
                                                    {
                                                        val: 'delete',
                                                        label: ' ',
                                                        style: { width: '1px' },
                                                    },
                                                ]}
                                                values={prettifyContractExperiences(
                                                    contractExperiences
                                                )}
                                                // onClick={(event, id) => {
                                                //     setExpToDelete(id);
                                                //     setOpen(true);
                                                // }}
                                            />
                                            <br />
                                            <Grid2
                                                container
                                                alignItems="flex-end">
                                                <Grid2 size={{ xs: 2, sm: 4 }}></Grid2>
                                                <Grid2 size={{ xs: 10, sm: 8 }}>
                                                    {totalExperiences /
                                                        MAX_PAGE <
                                                    1 ? null : (
                                                        <PaginationControlled
                                                            count={Math.ceil(
                                                                totalExperiences /
                                                                    MAX_PAGE
                                                            )}
                                                            page={page}
                                                            onChange={
                                                                handlePageChange
                                                            }
                                                        />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                            <Dialog
                                                open={openDelete}
                                                dialogTitle={'Attention!'}
                                                dialogContent={`Are you sure you want to remove the experience ${expToDelete}`}
                                                handleClose={() => {
                                                    setExpToDelete(null);
                                                    setOpenDelete(false);
                                                }}
                                                handleSubmit={
                                                    onSubmitDeleteExperiences
                                                }
                                            />
                                            <Dialog
                                                open={openEdit}
                                                dialogContent={renderEditForm()}
                                                handleClose={() => {
                                                    setOpenEdit(false);
                                                    setExpToEdit(null);
                                                }}
                                                hideTitle
                                                hideBack
                                                hideSubmit
                                            />
                                            <Dialog
                                                open={openDuplicate}
                                                dialogTitle={'Attention!'}
                                                dialogContent={`Are you sure you want to duplicate the contract: ${contract.name}?`}
                                                handleClose={() => {
                                                    setOpenDuplicate(false);
                                                }}
                                                handleSubmit={onDuplicateSubmit}
                                            />
                                        </>
                                    ) : null}
                                </Grid2>
                            </Grid2>
                        </div>
                    </Grid2>)
                    // ENDS CONTRACT INFO
                )}
            </div>
        </Layout>)
    );
};

ContractPage.displayName = 'ContractPage';

export default ContractPage;
