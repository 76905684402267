import { node, string } from 'prop-types';
import clsx from 'clsx';

import Menu from 'components/Menu';
import { useLayout } from './hook';
import { useMediaQuery } from '@mui/material';

const Layout = ({ children, contentClassName, isHome = false }) => {
  const { classes, open, toggleMenu, saved } = useLayout();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const drawerWidth = isMobile ? '100vw' : 240;

  const contentShiftStyles = open ? {
    transition: (theme) => theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
    maxHeight: '100vh',
    maxWidth: '100vw',
    overflow: 'hidden',
  } : {};


  return (
    <>
      {/* <AppBar
        open={open}
        toggleMenu={toggleMenu}
        saved={saved}
        isHome={isHome}
      /> */}
      <Menu open={open} toggleMenu={toggleMenu} />
      <main
         className={clsx(classes.content, contentClassName)}
         style={contentShiftStyles}
      >
        {children}
      </main>
    </>
  );
};

Layout.propTypes = {
  children: node.isRequired,
  contentClassName: string,
};

Layout.displayName = 'Layout';

export default Layout;
