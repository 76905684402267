import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import {
    Grid2,
    Typography,
    useMediaQuery,
    Divider,
    Button,
    Card,
    CardContent,
} from '@mui/material';

import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';
import BasicTable from 'core/libs/core-ui/components/Table';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import Checkbox from 'core/libs/core-ui/components/Input/Checkbox';
import Dialog from 'core/libs/core-ui/components/Dialog';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import EditIcon from 'assets/icons/edit-white.svg';

import { useTranslation } from 'core/contexts/localization';
import { useContract } from 'core/contexts/contract';
import { useHost } from 'core/contexts/host';
import { HostService } from 'core/api/services/host';

import PaymentPlatform from 'core/libs/types/PaymentPlatform/PaymentPlatform';

import { useStyles } from './styles';

const HostPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { t: translate } = useTranslation();

    const { contractExperiences, totalExperiences } = useContract();
    const { host, getHost, setHost, loading, addHost, updateHost } = useHost();

    const [hostChanged, setHostChanged] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [hostInfo, setHostInfo] = useState({});
    const [hostData, setHostData] = useState({});
    const [file, setFile] = useState();
    const [expToDelete, setExpToDelete] = useState(null);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const MAX_PAGE = 10;

    const locationParam = document.location.href.split('/');
    const hostIndex = locationParam[locationParam.length - 1];

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const onSubmitHostImage = async (values) => {
        if (file) {
            try {
                await HostService.uploadHostPicture(hostData.id, file);
                setCanEdit(false);
                setFile(null);
                getHost(hostIndex);
            } catch (err) {
                toast.error(err, {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
        }
    };

    // Reuse this for payment terminals
    const onSubmitDeleteExperiences = async () => {
        //   if (hostData.id && expToDelete) {
        //     try {
        //       await deleteContractExperiences(hostData.id, expToDelete);
        //       setCanEdit(false);
        //       setExpToDelete(null);
        //       setOpen(false)
        //       getHost(hostIndex);
        //       // getContractExperience(hostIndex,{
        //       //   pager: {
        //       //     page,
        //       //     pageSize: MAX_PAGE
        //       //   }
        //       // });
        //     } catch (err) {
        //       toast.error(err, {
        //         position: 'top-right',
        //         theme: 'colored',
        //       });
        //     }
        //   }
    };

    const onSubmitExperiences = async (values) => {
        //   const valuesClon = { ...values };
        //   if (contractData.id && contractExpChanged && valuesClon.experiences.length > 0) {
        //     try {
        //       const experiences = valuesClon.experiences.split(',');
        //       await updateContractExperiences(contractData.id, { experiences });
        //       setCanEdit(false);
        //       getHost(hostIndex);
        //       // getContractExperience(hostIndex,{
        //       //   pager: {
        //       //     page,
        //       //     pageSize: MAX_PAGE
        //       //   }
        //       // });
        //     } catch (err) {
        //       toast.error(err, {
        //         position: 'top-right',
        //         theme: 'colored',
        //       });
        //     }
        //   }
    };

    const onSubmit = async (values) => {
        try {
            if (hostIndex === '0') {
                const valuesClon = { ...values };
                // delete valuesClon.code;

                const newHost = await addHost(valuesClon);
                history.push(`/host/${newHost.id}`);
            } else {
                const valuesClon = { ...values };
                // delete valuesClon.code;
                if (hostChanged) {
                    await updateHost(valuesClon);
                }
                setCanEdit(false);
                getHost(hostIndex);                
            }
        } catch (err) {
            toast.error(err, {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    const isPaymentPlatformChecked = (id) => {
      if(!hostData.acceptedPaymentPlatforms || !hostData.acceptedPaymentPlatforms.length) { return false; }
      return hostData.acceptedPaymentPlatforms.some(
          (method) => method.id === id
      );
    };

    const onCheckPaymentPlatform = (value, paymentId) => {
      if(!Array.isArray(hostData.acceptedPaymentPlatforms)) { hostData.acceptedPaymentPlatforms = [];}
      if (!value) {
          hostData.acceptedPaymentPlatforms = hostData.acceptedPaymentPlatforms.filter(
                  (method) =>
                      method.id !== paymentId
              );
      } else {  
        hostData.acceptedPaymentPlatforms.push({
              id: paymentId,
          });
      }
      setHostData({...hostData});
    };

    useEffect(() => {
        setHost({});
        if (hostIndex == 0) {
            setCanEdit(true);
        } else {
            getHost(hostIndex);
        }
    }, []);

    useEffect(() => {
        setHostData(host);
    }, [host]);

    useEffect(() => {}, [page]);

    return (
        (<Layout contentClassName={classes.content}>
            <Title value={'Host'} />
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    // STARTS HOST INFO
                    (<Grid2>
                        <div className={classes.formContainer}>
                            <Title value={host.name} size={'l'} />
                            <Form
                                onSubmit={onSubmit}
                                initialValues={{
                                    ...hostData,
                                }}
                                render={({ handleSubmit, form }) => (
                                    <form id="form" onSubmit={handleSubmit}>
                                        <div>
                                            <div
                                                className={
                                                    classes.divEditButton
                                                }>
                                                <Typography
                                                    style={{
                                                        fontWeight: 'bold',
                                                        marginBottom: '10px',
                                                    }}
                                                    variant="h5"
                                                    className={classes.text}>
                                                    {translate(
                                                        'host.edit_host_data'
                                                    )}
                                                </Typography>
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        setCanEdit(true);
                                                    }}
                                                    style={{
                                                        height: 40,
                                                        margin: 'inherit',
                                                    }}
                                                    className={
                                                        classes.editButton
                                                    }>
                                                    <img
                                                        src={EditIcon}
                                                        width={20}
                                                    />
                                                    {isSmall ? null : (
                                                        <>&nbsp;</>
                                                    )}
                                                    {isSmall
                                                        ? null
                                                        : `${translate(
                                                              'host.edit'
                                                          )}`}
                                                </Button>
                                            </div>

                                            <Divider />

                                            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            id="id"
                                                            name="id"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'host.id'
                                                            )}
                                                            label={translate(
                                                                'host.id'
                                                            )}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            name="userId"
                                                            id="userId"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'host.userId'
                                                            )}
                                                            label={translate(
                                                                'host.userId'
                                                            )}
                                                            disabled={
                                                                hostIndex != 0
                                                            }
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                hostInfo.name =
                                                                    value;
                                                                setHostInfo(
                                                                    hostInfo
                                                                );
                                                                setHostChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="name"
                                                            name="name"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'host.name'
                                                            )}
                                                            label={translate(
                                                                'host.name'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                hostInfo.companySize =
                                                                    value;
                                                                setHostInfo(
                                                                    hostInfo
                                                                );
                                                                setHostChanged(
                                                                    true
                                                                );
                                                            }}
                                                            name="companySize"
                                                            id="companySize"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'host.companySize'
                                                            )}
                                                            label={translate(
                                                                'host.companySize'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                hostInfo.email =
                                                                    value;
                                                                setHostInfo(
                                                                    hostInfo
                                                                );
                                                                setHostChanged(
                                                                    true
                                                                );
                                                            }}
                                                            name="email"
                                                            id="email"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'host.email'
                                                            )}
                                                            label={translate(
                                                                'host.email'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                hostInfo.emailAlt =
                                                                    value;
                                                                setHostInfo(
                                                                    hostInfo
                                                                );
                                                                setHostChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="emailAlt"
                                                            name="emailAlt"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'host.emailAlt'
                                                            )}
                                                            label={translate(
                                                                'host.emailAlt'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                hostInfo.officePhone =
                                                                    value;
                                                                setHostInfo(
                                                                    hostInfo
                                                                );
                                                                setHostChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="officePhone"
                                                            name="officePhone"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'host.officePhone'
                                                            )}
                                                            label={translate(
                                                                'host.officePhone'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                hostInfo.mobilePhone =
                                                                    value;
                                                                setHostInfo(
                                                                    hostInfo
                                                                );
                                                                setHostChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="mobilePhone"
                                                            name="mobilePhone"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'host.mobilePhone'
                                                            )}
                                                            label={translate(
                                                                'host.mobilePhone'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            id="createdBy"
                                                            name="createdBy"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'host.createdBy'
                                                            )}
                                                            label={translate(
                                                                'host.createdBy'
                                                            )}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            id="createdOn"
                                                            name="createdOn"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'host.createdOn'
                                                            )}
                                                            label={translate(
                                                                'host.createdOn'
                                                            )}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                hostInfo.addressStreet =
                                                                    value;
                                                                setHostInfo(
                                                                    hostInfo
                                                                );
                                                                setHostChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="addressStreet"
                                                            name="addressStreet"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'host.addressStreet'
                                                            )}
                                                            label={translate(
                                                                'host.addressStreet'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                hostInfo.addressCity =
                                                                    value;
                                                                setHostInfo(
                                                                    hostInfo
                                                                );
                                                                setHostChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="addressCity"
                                                            name="addressCity"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'host.addressCity'
                                                            )}
                                                            label={translate(
                                                                'host.addressCity'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{xs: 12, sm: 4}}>
                                                        <Field
                                                            style={{
                                                                paddingTop: '0',
                                                            }}
                                                            disabled={!canEdit}
                                                            checked={isPaymentPlatformChecked(PaymentPlatform.IDS.cash)}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                onCheckPaymentPlatform(value, PaymentPlatform.IDS.cash)
                                                                setHostChanged( true );
                                                            }}
                                                            name="payByCash"
                                                            component={Checkbox}
                                                            labelComponent="Accept Cash"
                                                        />
                                                        <Field
                                                            style={{
                                                                paddingTop: '0',
                                                            }}
                                                            disabled={!canEdit}
                                                            checked={isPaymentPlatformChecked(PaymentPlatform.IDS.terminal)}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                              onCheckPaymentPlatform(value, PaymentPlatform.IDS.terminal)
                                                                setHostChanged( true );
                                                            }}
                                                            name="payByTerminal"
                                                            component={Checkbox}
                                                            labelComponent="Accept Terminal Payments"
                                                        />
                                                        <Field
                                                            style={{
                                                                paddingTop: '0',
                                                            }}
                                                            disabled={!canEdit}
                                                            errorMessage={translate(
                                                                'form.required'
                                                            )}
                                                            checked={isPaymentPlatformChecked(PaymentPlatform.IDS.link)}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                              onCheckPaymentPlatform(value, PaymentPlatform.IDS.link)
                                                                setHostChanged( true );
                                                            }}
                                                            name="payByLink"
                                                            component={Checkbox}
                                                            labelComponent="Accept Link Payments"
                                                        />
                                                        <Field
                                                            style={{
                                                                paddingTop: '0',
                                                            }}
                                                            disabled={!canEdit}
                                                            errorMessage={translate(
                                                                'form.required'
                                                            )}
                                                            checked={isPaymentPlatformChecked(PaymentPlatform.IDS.transfer)}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                              onCheckPaymentPlatform(value, PaymentPlatform.IDS.transfer)
                                                                setHostChanged( true );
                                                            }}
                                                            name="payByTransfer"
                                                            component={Checkbox}
                                                            labelComponent="Accept Transfer Payments"
                                                        />
                                                        <Field
                                                            style={{
                                                                paddingTop: '0',
                                                            }}
                                                            disabled={!canEdit}
                                                            errorMessage={translate(
                                                                'form.required'
                                                            )}
                                                            checked={isPaymentPlatformChecked(PaymentPlatform.IDS.stripe)}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                onCheckPaymentPlatform(value, PaymentPlatform.IDS.stripe)
                                                                setHostChanged( true );
                                                            }}
                                                            name="payByCard"
                                                            component={Checkbox}
                                                            labelComponent="Accept Card Payments"
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{xs: 12, sm: 8}}>
                                                        <Field
                                                            style={{
                                                                marginBottom:
                                                                    '2em',
                                                                width: '99%',
                                                            }}
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                hostInfo.description =
                                                                    value;
                                                                setHostInfo(
                                                                    hostInfo
                                                                );
                                                                setHostChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="description"
                                                            name="description"
                                                            component={
                                                                'textarea'
                                                            }
                                                            label={translate(
                                                                'host.description'
                                                            )}
                                                            disabled={!canEdit}
                                                            multiline
                                                            rows={4}
                                                            // initialCharacters={contract?.description?.length || 500}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </div>
                                        </div>

                                        <Grid2
                                            container
                                            justifyContent="flex-end"
                                            alignContent="flex-end">
                                            <Grid2
                                                container
                                                justifyContent={'space-between'}
                                                style={{ width: 'auto' }}>
                                                <Button
                                                    className="rounded mr-3 height-40 w-100px"
                                                    disabled={!canEdit}
                                                    type="button"
                                                    onClick={(e) => {
                                                        setCanEdit(false);
                                                        return form.reset(e);
                                                    }}>
                                                    {translate('btn_cancel')}
                                                </Button>

                                                <Button
                                                    onClick={(e) => {
                                                        setCanEdit(false);
                                                        return handleSubmit(e);
                                                    }}
                                                    className="rounded height-40 w-100px triper-button bg-green-300"
                                                    disabled={!canEdit}
                                                    type="submit">
                                                    {translate('btn_submit')}
                                                </Button>
                                            </Grid2>
                                        </Grid2>
                                    </form>
                                )}
                            />
                            <Grid2 container>
                                <Grid2 size={{xs: 12, sm: 6}}>
                                    {host.pictureURL ? (
                                        <>
                                            <Card
                                                style={{
                                                    minWidth: 275,
                                                    maxWidth: 375,
                                                }}>
                                                <CardContent>
                                                    <img
                                                        src={`${process.env.REACT_APP_CDN_URL}${host.pictureURL}`}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                </CardContent>
                                            </Card>
                                            <br />
                                        </>
                                    ) : null}
                                    {host.id ? (
                                        <>
                                            <Form
                                                onSubmit={onSubmitHostImage}
                                                initialValues={{
                                                    ...file,
                                                }}
                                                render={({
                                                    handleSubmit,
                                                    form,
                                                }) => (
                                                    <form
                                                        id="form"
                                                        onSubmit={handleSubmit}>
                                                        <Button component="label">
                                                            Upload
                                                            <input
                                                                id="file"
                                                                type="file"
                                                                onChange={(
                                                                    target
                                                                ) => {
                                                                    setFile(
                                                                        target
                                                                            ?.target
                                                                            ?.files[0]
                                                                    );
                                                                }}
                                                                hidden
                                                            />
                                                        </Button>
                                                        <Button
                                                            onClick={(e) => {
                                                                setCanEdit(
                                                                    false
                                                                );
                                                                return handleSubmit(
                                                                    e
                                                                );
                                                            }}
                                                            className=""
                                                            disabled={
                                                                !hostData.id ||
                                                                !file
                                                            }
                                                            type="submit">
                                                            {translate(
                                                                'btn_add'
                                                            )}
                                                        </Button>
                                                    </form>
                                                )}
                                            />
                                        </>
                                    ) : null}
                                </Grid2>
                                <Grid2 size={{xs: 12, sm: 6}}>
                                    {host.id ? (
                                        <>
                                            <Form
                                                onSubmit={onSubmitExperiences}
                                                initialValues={{
                                                    ...contractExperiences.experiences,
                                                }}
                                                render={({
                                                    handleSubmit,
                                                    form,
                                                }) => (
                                                    <form
                                                        id="form"
                                                        onSubmit={handleSubmit}>
                                                        <Title
                                                            size={'s'}
                                                            value={translate(
                                                                'host.paymentTerminals'
                                                            )}
                                                        />
                                                        <Grid2
                                                            container
                                                            spacing={1}>
                                                            <Grid2
                                                                size={{xs: 12, sm: 10}}
                                                                container
                                                                direction="row"
                                                                spacing={1}>
                                                                <Grid2 size={{xs: 12, sm: 6}}>
                                                                    <Field
                                                                        onFieldChange={(
                                                                            value
                                                                        ) => {
                                                                            // contractInfo.experiences = value;
                                                                            // setHostInfo(contractInfo);
                                                                            // setContractExpChanged(true);
                                                                        }}
                                                                        id="experiences"
                                                                        name="experiences"
                                                                        component={
                                                                            TextInput
                                                                        }
                                                                        hintText={translate(
                                                                            'host.paymentTerminalsIDHint'
                                                                        )}
                                                                        label={translate(
                                                                            'host.paymentTerminalsIDHint'
                                                                        )}
                                                                        // disabled={!canEdit}
                                                                    />
                                                                </Grid2>
                                                                <Grid2 size={{xs: 12, sm: 6}}>
                                                                    <Field
                                                                        onFieldChange={(
                                                                            value
                                                                        ) => {
                                                                            // contractInfo.experiences = value;
                                                                            // setHostInfo(contractInfo);
                                                                            // setContractExpChanged(true);
                                                                        }}
                                                                        id="experiences"
                                                                        name="experiences"
                                                                        component={
                                                                            TextInput
                                                                        }
                                                                        hintText={translate(
                                                                            'host.paymentTerminalsTagHint'
                                                                        )}
                                                                        label={translate(
                                                                            'host.paymentTerminalsTagHint'
                                                                        )}
                                                                        // disabled={!canEdit}
                                                                    />
                                                                </Grid2>
                                                            </Grid2>
                                                            <Grid2 size={{xs: 2, sm: 1}}>
                                                                <Button
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        // setCanEdit(false);
                                                                        return handleSubmit(
                                                                            e
                                                                        );
                                                                    }}
                                                                    className=""
                                                                    disabled={
                                                                        !hostData.id
                                                                    }
                                                                    type="submit">
                                                                    {translate(
                                                                        'btn_submit'
                                                                    )}
                                                                </Button>
                                                            </Grid2>
                                                        </Grid2>
                                                    </form>
                                                )}
                                            />
                                            <br />
                                            <BasicTable
                                                columns={[
                                                    {
                                                        align: 'left',
                                                        val: 'id',
                                                        label: 'Id',
                                                    },
                                                    {
                                                        val: 'tag',
                                                        label: 'Tag',
                                                    },
                                                    // { val: 'additional', label: 'Additional Comment', },
                                                ]}
                                                values={contractExperiences}
                                                onClick={(event, id) => {
                                                    setExpToDelete(id);
                                                    setOpen(true);
                                                }}
                                            />
                                            <br />
                                            <Grid2
                                                container
                                                alignItems="flex-end">
                                                <Grid2 size={{xs: 2, sm: 4}}></Grid2>
                                                <Grid2 size={{xs: 10, sm: 8}}>
                                                    {totalExperiences /
                                                        MAX_PAGE <
                                                    1 ? null : (
                                                        <PaginationControlled
                                                            count={Math.ceil(
                                                                totalExperiences /
                                                                    MAX_PAGE
                                                            )}
                                                            page={page}
                                                            onChange={
                                                                handlePageChange
                                                            }
                                                        />
                                                    )}
                                                </Grid2>
                                            </Grid2>
                                            <Dialog
                                                open={open}
                                                dialogTitle={'Attention!'}
                                                dialogContent={`Are you sure you want to remove the experience ${expToDelete}`}
                                                handleClose={() => {
                                                    setExpToDelete(null);
                                                    setOpen(false);
                                                }}
                                                handleSubmit={
                                                    onSubmitDeleteExperiences
                                                }
                                            />
                                        </>
                                    ) : null}
                                </Grid2>
                            </Grid2>
                        </div>
                    </Grid2>)
                    // ENDS HOST INFO
                )}
            </div>
        </Layout>)
    );
};

HostPage.displayName = 'HostPage';

export default HostPage;
