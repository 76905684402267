import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Grid2, } from '@mui/material';


import { useBooking } from 'core/contexts/booking';

import Layout from 'components/Layout';
import SearchBox from 'core/libs/core-ui/components/SearchBox';
import Title from 'core/libs/core-ui/components/Title';
import BasicTable from 'core/libs/core-ui/components/Table';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import { useDebounce } from 'core/libs/core-ui/utils/useDebounce';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';
import BookingStatus from 'core/libs/status/BookingStatus/BookingStatus';
import BookingOrigin from 'core/libs/types/BookingOrigin/BookingOrigin';

import { useStyles } from './styles';
import { PreviewExperience } from 'core/libs/core-ui/components/Preview/Entities';
import { PreviewService } from 'core/api/services/preview';

const BookingsPage = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { getBookings, bookings, loading, total } = useBooking();

  const classes = useStyles();
  const history = useHistory();

  const [emailSearch, setEmailSearch] = useState('');
  const [bookingCodeSearch, setBookingCodeSearch] = useState('');
  const emailDebounce = useDebounce(emailSearch, 300);
  const bookingCodeDebounce = useDebounce(bookingCodeSearch, 300);
  const [orderBy, setOrderBy] = useState('ci_created_on');
  const [direction, setDirection] = useState('desc');
  const [page, setPage] = useState(1);
  const MAX_PAGE = 20;

  useEffect(() => {
    getBookings({
      emailSearch: emailDebounce,
      bookingCodeSearch: bookingCodeDebounce,
      pager: {
        column: orderBy,
        direction,
        page,
        pageSize: MAX_PAGE,
      }
    });
  }, [page, orderBy, direction, emailDebounce, bookingCodeDebounce,]);

  const prettifyBookings = (bookings = []) => {
    return bookings.map(booking => {
      return {
        triper: `${booking.firstName} ${booking.lastName}`,
        statusString: BookingStatus.getLabel(booking.status),
        originString: BookingOrigin.getLabel(booking.originId),
        priceString: `${booking.currency} ${MoneyFormattedUtils(booking.price)}`,
        paidPriceString: `${booking.currency} ${MoneyFormattedUtils(booking.paidPrice)}`,
        commissionString: `${booking.currency} ${MoneyFormattedUtils(booking.commission)}`,
        createdOnString: DateFormattedUtils(booking.createdOn, null, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZone: booking.timezone || 'UTC',
      }),
        expId: <PreviewExperience id={booking.experienceDetailId} label="name" previewService={PreviewService}/>,
        dateString: DateFormattedUtils(booking.dateUTC, null, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZone: booking.timezone || 'UTC',
      }),
        feeRateString: `${booking.feeRate}%`,
        ...booking
      }
    })
  };
  
  const columns = [
    { align: 'left', val: 'id', label: 'Id', column: 'ci_id', },
    { align: 'left', val: 'code', label: 'Code', column: 'ci_code', },
    { val: 'triper', label: 'Triper', column: 'ci_first_name', style: { minWidth: '200px' }, },
    { val: 'email', label: 'Email', column: 'ci_email', },
    { val: 'expId', label: 'ExpName', column: 'ci_experience_detail_id' },
    { val: 'mobilePhone', label: 'Mobile Phone', column: 'ci_mobile_phone' },
    { val: 'dateString', label: 'Date of Activity', column: 'ci_date_utc', style: { minWidth: '250px' }, },
    { val: 'createdOnString', label: 'Created On', column: 'ci_created_on', style: { minWidth: '250px' }, },
    { align: 'center', val: 'quantity', label: 'Quantity', column: 'ci_quantity', },
    { align: 'right', val: 'paidPriceString', label: 'Paid Amount', column: 'ci_paid_price', style: { minWidth: '150px' }, },
    { align: 'right', val: 'priceString', label: 'Price', column: 'ci_price', style: { minWidth: '150px' }, },
    { align: 'right', val: 'commissionString', label: 'Comission', column: 'ci_comission', style: { minWidth: '150px' }, },
    { align: 'center', val: 'feeRateString', label: 'Fee Rate', column: 'ci_fee_rate', style: { minWidth: '150px' }, },
    { align: 'center', val: 'paymentId', label: 'PaymentId', column: 'ci_payment_id', },
    { align: 'left', val: 'originString', label: 'Origin', column: 'ci_origin_id', },
    { align: 'center', val: 'contractId', label: 'ContractId', column: 'ci_contract_id' },
    { align: 'left', val: 'statusString', label: 'Status', column: 'ci_status', },
  ];
  
  const mobileColumns = [
    { align: 'left', val: 'id', label: 'Id', column: 'ci_id', },
    { val: 'code', label: 'Code', column: 'ci_code', },
    { val: 'email', label: 'Email', column: 'ci_email', },
    { val: 'expId', label: 'ExpName', column: 'ci_experience_detail_id' },
    { val: 'dateString', label: 'Date of Activity', column: 'ci_date_utc', style: { minWidth: '250px' }, },
    { val: 'createdOnString', label: 'Created On', column: 'ci_created_on', style: { minWidth: '250px' }, },
    { align: 'right', val: 'paidPriceString', label: 'Paid Amount', column: 'ci_paid_price', style: { minWidth: '150px' }, },
    { align: 'right', val: 'priceString', label: 'Price', column: 'ci_price', style: { minWidth: '150px' }, },
    { val: 'originString', label: 'Origin', column: 'ci_origin_id', },
    { align: 'left', val: 'statusString', label: 'Status', column: 'ci_status', },
  ];
  
  const handleSort = (column) => {
    if (orderBy === column) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(column);
      setDirection('asc');
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const onChangeEmailSearch = (event) => {
    setEmailSearch(event.target.value);
  };

  const onChangeBookingCodeSearch = (event) => {
    setBookingCodeSearch(event.target.value);
  };

  const onClick = (event, id) => {
    history.push(`/booking/${id}`)
  }

  return (
    <Layout contentClassName={classes.content}>
      <Title value={'Bookings'} />
      <div style={{ marginBottom: "25px" }}>
        <SearchBox
          onChange={onChangeEmailSearch}
          value={emailSearch}
          label={'Email'}
          style={{ width: '14rem', marginRight: '1rem' }}
        />
        <SearchBox
          onChange={onChangeBookingCodeSearch}
          value={bookingCodeSearch}
          label={'Code'}
          style={{ width: '5rem' }}
        />
      </div>
      <div>
        {loading ? (
          <CircularIndeterminate />
        ) : (
          <>
            {/* <Button
              onClick={(e) => {
                history.push(`/booking/0`)
              }}
            >ADD</Button> */}
            <BasicTable
              size='small'
              columns={!isSmall ? columns : mobileColumns}
              handleSort={handleSort}
              orderBy={orderBy}
              direction={direction}
              values={prettifyBookings(bookings)}
              onClick={onClick} />
            <br />
            <Grid2 container alignItems='flex-end'>
              <Grid2 size={{xs: 1, sm: 7}}></Grid2>
              <Grid2 size={{xs: 11, sm: 5}}>
                {
                  total / MAX_PAGE < 1 ? null :
                    (<PaginationControlled count={(Math.ceil(total / MAX_PAGE))} page={page} onChange={handlePageChange} />)
                }
              </Grid2>
            </Grid2>
          </>
        )
        }
      </div>
    </Layout>
  );

};

BookingsPage.displayName = 'BookingsPage';

export default BookingsPage;
