import { useState } from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    IconButton,
    useMediaQuery,
    ListItemButton,
} from '@mui/material';
import { ExitToApp, Close } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { useStyles } from './styles';
import { useTranslation } from 'core/contexts/localization';
import { useAuth } from 'core/contexts/auth';
import WhiteLogo from 'assets/logo/white.png';
import BlackLogo from 'assets/logo/black.png';
import ClosedMenuLogo from 'assets/logo/closed-menu.png';
import { triperMenu, experiencerMenu } from './items';
// import TermsAndConditions from './TermsAndConditions';

const Menu = ({ open, toggleMenu }) => {
    const history = useHistory();
    const { t: translate } = useTranslation();
    const classes = useStyles();

    const { logout, role } = useAuth();

    const items = role === 'triper' ? triperMenu : experiencerMenu;
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const drawerWidth = isMobile ? '100vw' : 240;


    const [openModal, setOpenModal] = useState(false);

    const drawerStyles = {
      width: drawerWidth,
      flexShrink: 0,
    };
  
    const drawerOpenStyles = {
      width: drawerWidth,
      transition: (theme) =>
        theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
    };
  
    const drawerCloseStyles = {
      transition: (theme) => theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 60,
  }
  

    const onLogoutClick = async () => {
        await logout();
        history.push('/');
    };

    return (<>
        {isMobile ? (
            <IconButton
                color="white"
                onClick={toggleMenu}
                edge="start"
                className={classes.menuButton}
                size="large">
                <MenuIcon />
            </IconButton>
        ) : null}
        <Drawer
            sx={drawerStyles}
            variant={isMobile ? 'persistent' : 'permanent'}
            anchor="left"
            open={open}
            PaperProps={{
              sx: open ? drawerOpenStyles : drawerCloseStyles,
            }}
        >
            <div className={classes.header}>
                {open ? (
                    <img
                        alt="global-triper-logo"
                        className={classes.logo}
                        src={BlackLogo}
                    />
                ) : (
                    <img
                        alt="global-triper-logo"
                        className={classes.closedMenuLogo}
                        src={ClosedMenuLogo}
                    />
                )}

                {isMobile && (
                    <IconButton style={{ color: '#333' }} onClick={() => toggleMenu()} size="large">
                        <Close />
                    </IconButton>
                )}
            </div>
            <List className={classes.list}>
                {!isMobile && (
                    <ListItem
                        className={classes.openMenu}
                        disablePadding
                        onClick={toggleMenu}>
                        <ListItemButton>
                            <ListItemIcon>
                                <MenuIcon />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                )}
                {items.map(({ name, icon, link }) => (
                    <ListItem
                        key={name}
                        disablePadding
                        sx={{display: 'block'}}
                        onClick={() => history.push(link)}>
                        <ListItemButton>
                            <ListItemIcon>
                                <img
                                    alt={name}
                                    style={{
                                        maxWidth: '1.7em',
                                        maxHeight: '1.7em',
                                    }}
                                    src={icon}
                                    />
                            </ListItemIcon>
                            {!!open && (
                                <ListItemText primary={translate(name)} />
                                )}
                        </ListItemButton>
                    </ListItem>
                ))}

                <ListItem
                    key={'sign-out'}
                    className={classes.signOut}
                    disablePadding
                    onClick={onLogoutClick}>
                    <ListItemButton>
                        <ListItemIcon>
                            <ExitToApp />
                        </ListItemIcon>
                        {!!open && <ListItemText primary="Sign Out" />}
                    </ListItemButton>
                </ListItem>

                {!!open && (
                    <Typography className={classes.copyright}>
                        © Global Triper -
                        <a
                            onClick={(e) => {
                                setOpenModal(true);
                                e.preventDefault();
                            }}>
                            {` ${translate(
                                'termsAndConditions.privacyPolicy'
                            )}`}
                        </a>
                    </Typography>
                )}
            </List>
        </Drawer>
        {/* <TermsAndConditions
    open={openModal}
    onClose={() => setOpenModal(false)}
  /> */}
    </>);
};

Menu.displayName = 'Menu';

export default Menu;
