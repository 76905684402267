import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Grid2 } from '@mui/material';

import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';
import BasicTable from 'core/libs/core-ui/components/Table';
import SearchBox from 'core/libs/core-ui/components/SearchBox';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

import { useExperience } from 'core/contexts/experience';

import { useStyles } from './styles';

const ExperiencesPage = () => {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const history = useHistory();
  const { getExperiences, experiences, loading, total } = useExperience();
  const [nameSearch, setNameSearch] = useState('');
  const [experiencerIdSearch, setExperiencerIdSearch] = useState('');
  const [experienceIdSearch, setExperienceIdSearch] = useState('');
  const [orderBy, setOrderBy] = useState('ci_id');
    const [direction, setDirection] = useState('desc');
  const [page, setPage] = useState(1);
  const MAX_PAGE = 20;

  useEffect(() => {
    getExperiences({
      nameSearch,
      experiencerIdSearch,
      experienceIdSearch,
      pager:{
        column: orderBy,
        direction,
        page,
        pageSize:MAX_PAGE
    }});
  }, []);

  useEffect(() => {
    getExperiences({
      nameSearch,
      experiencerIdSearch,
      experienceIdSearch,
      pager:{
        column: orderBy,
        direction,
        page,
        pageSize:MAX_PAGE
    }});
  }, [orderBy, direction, page, nameSearch, experiencerIdSearch, experienceIdSearch]);


  //   const prettifyExperiences = (experiencers = []) => {
//     return experiencers.map((experiencer) => {
//         return {
//             createdOnString: DateFormattedUtils(experiencer.createdOn),
//             whatsappString: experiencer.notificationWhatsapp ? 'Yes' : 'No',
//             ...experiencer,
//         };
//     });
// };

  const columns = [
    { align: 'left', val: 'id', label: 'Id', column: 'ci_id' },
    { align: 'left', val: 'experiencerId', label: 'T.O. Id', column: 'ci_experiencer_id' },
    { val: 'name', label: 'Name', },
    { val: 'location', label: 'Location', column: 'ci_location' },
    { val: 'publishedOn', label: 'Published On', },
    { align: 'center', val: 'status', label: 'Status', },
  ];

  const handleSort = (column) => {
    if (orderBy === column) {
        setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
        setOrderBy(column);
        setDirection('asc');
    }
};

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const onChangeSetNameSearch = (event) => {
    setNameSearch(event.target.value);
  };

  const onChangeExperienceIdSearch = (event) => {
    setExperienceIdSearch(event.target.value);
  };

  const onChangeExperiencerIdSearch = (event) => {
    setExperiencerIdSearch(event.target.value);
  };

  const onClick = (event, id) => {
    history.push(`/experience/${id}`);
  };


  return (
    <Layout contentClassName={classes.content}>
      <Title value={'Experiences'} />
      <div style={{ marginBottom: "25px"}}>
        <SearchBox
          onChange={onChangeExperienceIdSearch}
          value={experienceIdSearch}
          label={'Experience Id'}
          style={{ width: '14rem', marginRight: '1rem'  }}
        />
        <SearchBox
          onChange={onChangeSetNameSearch}
          value={nameSearch}
          label={'Name'}
          style={{ width: '14rem', marginRight: '1rem' }}
        />
        <SearchBox
          onChange={onChangeExperiencerIdSearch}
          value={experiencerIdSearch}
          label={'Tour Operator Id'}
          style={{ width: '14rem' }}
        />
      </div>
      <div>
        {loading ? (
          <CircularIndeterminate />
        ) : (
          <>
            <BasicTable 
              columns={columns}
              handleSort={handleSort}
              orderBy={orderBy}
              direction={direction}
              values={experiences}
              onClick={onClick}/>  
            <br />
            <Grid2 container alignItems='flex-end'>
              <Grid2 size={{xs: 1, sm: 7}}></Grid2>
              <Grid2 size={{xs: 11, sm: 5}}>
                {
                  total/MAX_PAGE < 1 ? null:
                (<PaginationControlled count={(Math.ceil(total/MAX_PAGE))} page={page} onChange={handlePageChange} />)
                }
              </Grid2>
            </Grid2>
          </>
        )
        }
      </div>
    </Layout>
  );
};

ExperiencesPage.displayName = 'ExperiencesPage';

export default ExperiencesPage;
