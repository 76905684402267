import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import {
    Grid2,
    Typography,
    useMediaQuery,
    Divider,
    Button,
} from '@mui/material';

import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import Checkbox from 'core/libs/core-ui/components/Input/Checkbox';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import EditIcon from 'assets/icons/edit-white.svg';

import { useTranslation } from 'core/contexts/localization';
import { useResource } from 'core/contexts/resource';

import { useStyles } from './styles';

const ResourcePage = () => {
    const classes = useStyles();
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { t: translate } = useTranslation();

    const {
        loading,
        resource,
        getResource,
        setResource,
        addResource,
        updateResource,
    } = useResource();

    const [resourceChanged, setResourceChanged] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [resourceInfo, setResourceInfo] = useState({});
    const [resourceData, setResourceData] = useState({});

    const locationParam = document.location.href.split('/');
    const resourceIndex = parseInt(locationParam[locationParam.length - 1]);

    const onSubmit = async (values) => {
        try {
            if (resourceIndex === 0) {
                const valuesClone = { ...values };
                const newResource = await addResource(valuesClone);
                history.push(`/resource/${newResource.id}`);
            } else {
                const valuesClone = { ...values };
                if (resourceChanged) {
                    await updateResource(valuesClone);
                }
                setCanEdit(false);
                getResource(resourceIndex);
            }
        } catch (err) {
            toast.error(err, {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    useEffect(() => {
        setResource({});
        if (resourceIndex === 0) {
            setCanEdit(true);
        } else {
            getResource(resourceIndex);
        }
    }, []);

    useEffect(() => {
        setResourceData(resource);
    }, [resource]);

    return (
        (<Layout contentClassName={classes.content}>
            <Title value={'Resource'} />
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    // STARTS RESOURCE INFO
                    (<Grid2>
                        <div className={classes.formContainer}>
                            <Title value={resource.name} size={'l'} />
                            <Form
                                onSubmit={onSubmit}
                                initialValues={{
                                    ...resourceData,
                                }}
                                render={({ handleSubmit, form }) => (
                                    <form id="form" onSubmit={handleSubmit}>
                                        <div>
                                            <div
                                                className={
                                                    classes.divEditButton
                                                }>
                                                <Typography
                                                    style={{
                                                        fontWeight: 'bold',
                                                        marginBottom: '10px',
                                                    }}
                                                    variant="h5"
                                                    className={classes.text}>
                                                    {translate(
                                                        'resource.edit_resource_data'
                                                    )}
                                                </Typography>
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        setCanEdit(true);
                                                    }}
                                                    style={{
                                                        height: 40,
                                                        margin: 'inherit',
                                                    }}
                                                    className={
                                                        classes.editButton
                                                    }>
                                                    <img
                                                        src={EditIcon}
                                                        width={20}
                                                    />
                                                    {isSmall ? null : (
                                                        <>&nbsp;</>
                                                    )}
                                                    {isSmall
                                                        ? null
                                                        : `${translate(
                                                              'resource.edit'
                                                          )}`}
                                                </Button>
                                            </div>

                                            <Divider />

                                            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            id="id"
                                                            name="id"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'resource.id'
                                                            )}
                                                            label={translate(
                                                                'resource.id'
                                                            )}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            name="experiencerId"
                                                            id="experiencerId"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'resource.experiencerId'
                                                            )}
                                                            label={translate(
                                                                'resource.experiencerId'
                                                            )}
                                                            disabled={ !canEdit }
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                resourceInfo.name =
                                                                    value;
                                                                setResourceInfo(
                                                                    resourceInfo
                                                                );
                                                                setResourceChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="name"
                                                            name="name"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'resource.name'
                                                            )}
                                                            label={translate(
                                                                'resource.name'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                resourceInfo.resourceCategoryId =
                                                                    value;
                                                                setResourceInfo(
                                                                    resourceInfo
                                                                );
                                                                setResourceChanged(
                                                                    true
                                                                );
                                                            }}
                                                            name="resourceCategoryId"
                                                            id="resourceCategoryId"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'resource.resourceCategoryId'
                                                            )}
                                                            label={translate(
                                                                'resource.resourceCategoryId'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                resourceInfo.email =
                                                                    value;
                                                                setResourceInfo(
                                                                    resourceInfo
                                                                );
                                                                setResourceChanged(
                                                                    true
                                                                );
                                                            }}
                                                            name="email"
                                                            id="email"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'resource.email'
                                                            )}
                                                            label={translate(
                                                                'resource.email'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                resourceInfo.mobilePhone =
                                                                    value;
                                                                setResourceInfo(
                                                                    resourceInfo
                                                                );
                                                                setResourceChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="mobilePhone"
                                                            name="mobilePhone"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'resource.mobilePhone'
                                                            )}
                                                            label={translate(
                                                                'resource.mobilePhone'
                                                            )}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            id="createdBy"
                                                            name="createdBy"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'resource.createdBy'
                                                            )}
                                                            label={translate(
                                                                'resource.createdBy'
                                                            )}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{xs: 12, sm: 6}}>
                                                        <Field
                                                            id="createdOn"
                                                            name="createdOn"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={translate(
                                                                'resource.createdOn'
                                                            )}
                                                            label={translate(
                                                                'resource.createdOn'
                                                            )}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </div>
                                        </div>

                                        <Grid2
                                            container
                                            justifyContent="flex-end"
                                            alignContent="flex-end">
                                            <Grid2
                                                container
                                                justifyContent={'space-between'}
                                                style={{ width: 'auto' }}>
                                                <Button
                                                    className="rounded mr-3 height-40 w-100px"
                                                    disabled={!canEdit}
                                                    type="button"
                                                    onClick={(e) => {
                                                        setCanEdit(false);
                                                        return form.reset(e);
                                                    }}>
                                                    {translate('btn_cancel')}
                                                </Button>

                                                <Button
                                                    onClick={(e) => {
                                                        setCanEdit(false);
                                                        return handleSubmit(e);
                                                    }}
                                                    className="rounded height-40 w-100px triper-button bg-green-300"
                                                    disabled={!canEdit}
                                                    type="submit">
                                                    {translate('btn_submit')}
                                                </Button>
                                            </Grid2>
                                        </Grid2>
                                    </form>
                                )}
                            />
                        </div>
                    </Grid2>)
                    // ENDS RESOURCE INFO
                )}
            </div>
        </Layout>)
    );
};

ResourcePage.displayName = 'ResourcePage';

export default ResourcePage;
