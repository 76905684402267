import { fetch, Method } from 'core/api/fetch';

export const PreviewService = {

    getPreview: async ({ entity, id, queryParams = {} }) => {
        if (!entity || !id) {
            throw new Error('Both entity and id are required to fetch a preview.');
        }

        try {
            console.log(entity, id)
            // Convierte los query params a un string de URL si existen
            const queryString = Object.keys(queryParams).length > 0 
                ? `?${new URLSearchParams(queryParams).toString()}` 
                : '';

            const url = `/api/v1.1.1/preview/${entity}/${id}${queryString}`;

            const response = await fetch(url, {
                method: Method.GET,
                authenticated: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response;
        } catch (error) {
            console.error(`Error fetching preview for ${entity} with ID ${id}:`, error);
            throw error;
        }
    }

}
