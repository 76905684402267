import React, { useState } from 'react';
import {
    TextField,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { CalendarToday, Close } from '@mui/icons-material';
import DatePicker from '../DatePicker';
import { parse } from 'date-fns';

function DatePickerWithInput({ label, value, onChange, disabled=false, ...props }) {
    const [showCalendar, setShowCalendar] = useState(false);

    const handleCalendarToggle = () => {
      if(disabled){
        return false;
      }
        setShowCalendar(!showCalendar);
    };

    const handleDateChange = (date) => {
        onChange(date);
        setShowCalendar(false);
    };

    return (
        (<div style={{ position: 'relative', ...props.style }}>
            <TextField
                className={props.classes}
                style={props?.inputProps?.style}
                id="input-with-icon-textfield"
                label={label}
                value={value}
                disabled={disabled}
                fullWidth
                variant="outlined"
                slotProps={{
                  input: {
                    endAdornment: (
                        <InputAdornment position="end">
                            {value && (
                                <>
                                <IconButton onClick={handleCalendarToggle} size="large">
                                    <CalendarToday />
                                 </IconButton>
                                <IconButton onClick={() => handleDateChange({})} size="large">
                                    <Close />
                                </IconButton>
                                </>
                            )}
                        </InputAdornment>
                    ),
                    startAdornment: (
                        <InputAdornment position="start">
                            {!value && (
                            <IconButton onClick={handleCalendarToggle} size="large">
                                <CalendarToday />
                            </IconButton>
                            )}
                        </InputAdornment>
                    ),
                  }
                }}
            />
            {showCalendar && (
                <div style={{
                        position: 'absolute',
                        zIndex: 1000,
                    }}>
                    <DatePicker
                        sx={{
                            '& .MuiPickersDay-root': {
                                color: '#7d7987',
                                fontWeight: '500 !important',
                                fontSize: '12px !important',
                                background: 'transparent',
                            },
                            '& .MuiDayCalendar-weekDayLabel': {
                                color: '#7d7987',
                                fontWeight: '500 !important',
                                fontSize: '12px !important',
                                background: 'transparent'
                            },
                            '& .Mui-selected': {
                                color: '#fff',
                                borderRadius: '18px',
                                backgroundColor: '#363740 !important',
                                '&:hover': {
                                  backgroundColor: '#363740',
                                }
                          },
                        }}
                        slotProps={{
                            actionBar: {
                              actions: []
                            },
                            toolbar: {
                              sx: {display: 'none'}
                            },
                            calendarHeader: {
                              disabled: true,
                            },
                          }}
                        label={label}
                        value={value instanceof Date || typeof value !== 'string' ? value : parse(value, 'EEE MMM dd yyyy', new Date())}
                        onChange={handleDateChange}
                    />
                </div>
            )}
        </div>)
    );
}
export default DatePickerWithInput;
