import { useEffect, useState } from 'react';
import { Grid2, } from '@mui/material';

import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';
import BasicTable from 'core/libs/core-ui/components/Table';
import SearchBox from 'core/libs/core-ui/components/SearchBox';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

import { useUser } from 'core/contexts/user';

import { useStyles } from './styles';

const UsersPage = () => {
  const classes = useStyles();

  const { getUsers, users, loading, total } = useUser();

  const [emailSearch, setEmailSearch] = useState('');
  const [page, setPage] = useState(1);
  const MAX_PAGE = 20;

  useEffect(() => {
    getUsers({
      emailSearch,
      pager: {
        page,
        pageSize: MAX_PAGE
      }
    });
  }, []);

  useEffect(() => {
    getUsers({
      emailSearch,
      pager: {
        page,
        pageSize: MAX_PAGE
      }
    });
  }, [page, emailSearch]);
  
  const columns = [
    { align: 'left', val: 'id', label: 'ID'},
    { val: 'email', label: 'Email'},
    { val: 'createdOn', label: 'Created On'},
    { val: 'lastLogin', label: 'Last Login'},
    { val: 'lastPasswordReset', label: 'Last Password Reset'},
    { align: 'center', val: 'status', label: 'Status'}
  ];

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const onChangeEmailSearch = (event) => {
    setEmailSearch(event.target.value);
  };

  return (
    <Layout contentClassName={classes.content}>
      <Title value={'Users'} />
      <div style={{marginBottom:"25px"}}>
        <Grid2 container
              direction="row"
              justifyContent="space-between"
              alignItems="center">
        
          <SearchBox
            onChange={onChangeEmailSearch}
            value={emailSearch}
            label={'Email'}
            style={{ width: '14rem', marginRight: '1rem' }}
            />
        
        
          {/* <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              history.push(`/host/0`)
            }}
            >ADD</Button> */}
        
      </Grid2>
      </div>
      <div>
        {loading ? (
          <CircularIndeterminate />
        ) : (
          <>
            <BasicTable columns={columns} values={users} />
            <br />
            <Grid2 container alignItems='flex-end'>
              <Grid2 size={{xs: 1, sm: 7}}></Grid2>
              <Grid2 size={{xs: 11, sm: 5}}>
                {
                  total/MAX_PAGE < 1 ? null:
                (<PaginationControlled count={(Math.ceil(total/MAX_PAGE))} page={page} onChange={handlePageChange} />)
                }
              </Grid2>
            </Grid2>
          </>
        )
        }
      </div>
    </Layout>
  );
};

UsersPage.displayName = 'UsersPage';

export default UsersPage;
