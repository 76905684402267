import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(2),
    },
    text: {
        marginTop: theme.spacing(2),
    },
    marginLeft: {
        marginLeft: theme.spacing(2),
    },

    formTitle: {
        marginBottom: theme.spacing(2),
    },
    formFormControl: {
        marginBottom: theme.spacing(3),
    },
    approveButton: {
      height: '100%',
      color: 'white',
      backgroundColor: theme.palette.utriper.green,
    },
}));
