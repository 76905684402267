import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Grid2, Button, } from '@mui/material';

import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';
import BasicTable from 'core/libs/core-ui/components/Table';
import SearchBox from 'core/libs/core-ui/components/SearchBox';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';

import { useBundle } from 'core/contexts/bundle';

import { useStyles } from './styles';

const BundlesPage = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { getBundles, bundles, loading, total } = useBundle();

  const classes = useStyles();
  const history = useHistory();

  const [nameSearch, setNameSearch] = useState('');

  const [page, setPage] = useState(1);
  const MAX_PAGE = 20;

  useEffect(() => {
    getBundles({
      nameSearch,
      pager: {
        page,
        pageSize: MAX_PAGE
      }
    });
  }, []);

  useEffect(() => {
    getBundles({
      nameSearch,
      pager: {
        page,
        pageSize: MAX_PAGE
      }
    });
  }, [page, nameSearch]);

  const columns = [
    { align: 'left', val: 'id', label: "Id" , column: "ci_id",},
    { val: 'name', label: "Name" , column: "ci_name",},
    { val: 'ownerId', label: "Owner" , column: "ci_owner",},
    { val: 'startString', label: "Start" , column: "ci_start",},
    { val: 'endString', label: "End" , column: "ci_end",},
    { val: 'createdOnString', label: "Created On" , column: "ci_created_on",},
    { val: 'createdBy', label: "Created By" , column: "ci_created_by",},
    { val: 'updatedOnString', label: "Updated On" , column: "ci_updated_On",},
    { val: 'updatedBy', label: "Updated By" , column: "ci_updated_by",},
    { align: 'center', val: 'status', label: "Status" , column: "ci_status",},
  ];
  
  const mobileColumns = [
    { align: 'left', val: 'id', label: 'Id', },
    { val: 'name', label: 'Name', },
    { align: 'center', val: 'status', label: 'Status', },
  ];

  const prettifyBundles = (bundles = []) => {
    return bundles.map(bundle => {
      return {
        createdOnString: DateFormattedUtils(bundle.createdOn),
        updatedOnString: DateFormattedUtils(bundle.updatedOn),
        startString: bundle.start ? DateFormattedUtils(bundle.start) : "",
        endString: bundle.end ? DateFormattedUtils(bundle.end) : "",
        ...bundle
      }
    })
  };
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const onChangeNameSearch = (event) => {
    setNameSearch(event.target.value);
  };

  const onClick = (event, id) => {
    history.push(`/bundle/${id}`)
  }

  return (
    <Layout contentClassName={classes.content}>
      <Title value={'Bundles'} />
      <div style={{marginBottom:"25px"}}>
        <Grid2 container
              direction="row"
              justifyContent="space-between"
              alignItems="center">
          
            <SearchBox
              onChange={onChangeNameSearch}
              value={nameSearch}
              label={'Name'}
              style={{ width: '14rem', marginRight: '1rem' }}
              />
          
          
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                history.push(`/bundle/0`)
              }}
              >ADD</Button>
          
        </Grid2>
      </div>
      <div>
        {loading ? (
          <CircularIndeterminate />
        ) : (
          <>
            <BasicTable columns={!isSmall ? columns : mobileColumns} values={prettifyBundles(bundles)} onClick={onClick} />
            <br />
            <Grid2 container alignItems='flex-end'>
              <Grid2 size={{xs: 1, sm: 7}}></Grid2>
              <Grid2 size={{xs: 11, sm: 5}}>
                {
                  total/MAX_PAGE < 1 ? null:
                (<PaginationControlled count={(Math.ceil(total/MAX_PAGE))} page={page} onChange={handlePageChange} />)
                }
              </Grid2>
            </Grid2>
          </>
        )
        }
      </div>
    </Layout>
  );
};

BundlesPage.displayName = 'BundlesPage';

export default BundlesPage;
