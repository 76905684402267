import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid2, Button } from '@mui/material';

import { useResource } from 'core/contexts/resource';

import Layout from 'components/Layout';
import SearchBox from 'core/libs/core-ui/components/SearchBox';
import Title from 'core/libs/core-ui/components/Title';
import BasicTable from 'core/libs/core-ui/components/Table';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import { useDebounce } from 'core/libs/core-ui/utils/useDebounce';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';

import { useStyles } from './styles';

const ResourcesPage = () => {
    const { getResources, resources, loading, total } = useResource();

    const classes = useStyles();
    const history = useHistory();

    const [nameSearch, setNameSearch] = useState('');
    const [emailSearch, setEmailSearch] = useState('');
    const [experiencerIdSearch, setExperiencerIdSearch] = useState('');
    const nameDebounce = useDebounce(nameSearch, 300);
    const emailDebounce = useDebounce(emailSearch, 300);
    const experiencerIdDebounce = useDebounce(experiencerIdSearch, 300);
    const [orderBy, setOrderBy] = useState('ci_id');
    const [direction, setDirection] = useState('desc');
    const [page, setPage] = useState(1);
    const MAX_PAGE = 20;

    useEffect(() => {
        getResources({
            nameSearch: nameDebounce,
            emailSearch: emailDebounce,
            experiencerIdSearch: experiencerIdDebounce,
            pager: {
                column: orderBy,
                direction,
                page,
                pageSize: MAX_PAGE,
            },
        });
    }, [
        page,
        orderBy,
        direction,
        nameDebounce,
        emailDebounce,
        experiencerIdDebounce,
    ]);

    const prettifyResources = (resources = []) => {
        return resources.map((resource) => {
            return {
                createdOnString: DateFormattedUtils(resource.createdOn),
                ...resource,
            };
        });
    };

    const columns = [
        { align: 'left', val: 'id', label: 'Id', column: 'ci_id' },
        {
            align: 'left',
            val: 'experiencerId',
            label: 'T.O. Id',
            column: 'ci_experiencer_id',
        },
        { val: 'name', label: 'Name', column: 'ci_name' },
        { val: 'email', label: 'Email', column: 'ci_email' },
        {
            val: 'mobilePhone',
            label: 'Mobile Phone',
            column: 'ci_mobile_phone',
        },
        {
            val: 'createdOnString',
            label: 'Created On',
            column: 'ci_created_on',
        },
        { val: 'createdBy', label: 'Created By', column: 'ci_created_by' },
        {
            align: 'center',
            val: 'resourceCategoryId',
            label: 'Resource Category Id',
            column: 'ci_resource_category_id',
        },
        {
            val: 'resourceCategory',
            label: 'Resource Category',
            column: 'ci_resource_category_id',
        },
    ];

    const handleSort = (column) => {
        if (orderBy === column) {
            setDirection(direction === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(column);
            setDirection('asc');
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const onChangeNameSearch = (event) => {
        setNameSearch(event.target.value);
    };

    const onChangeEmailSearch = (event) => {
        setEmailSearch(event.target.value);
    };

    const onChangeExperiencerIdSearch = (event) => {
        setExperiencerIdSearch(event.target.value);
    };

    const onClick = (event, id) => {
        history.push(`/resource/${id}`);
    };

    return (
        <Layout contentClassName={classes.content}>
            <Title value={'Resources'} />
            <div style={{ marginBottom: '25px' }}>
            <Grid2 container
              direction="row"
              justifyContent="space-between"
              alignItems="center">
                <Grid2>
                  <SearchBox
                      onChange={onChangeNameSearch}
                      value={nameSearch}
                      label={'Name'}
                      style={{ width: '14rem', marginRight: '1rem' }}
                  />
                  <SearchBox
                      onChange={onChangeEmailSearch}
                      value={emailSearch}
                      label={'Email'}
                      style={{ width: '14rem', marginRight: '1rem' }}
                  />
                  <SearchBox
                      onChange={onChangeExperiencerIdSearch}
                      value={experiencerIdSearch}
                      label={'T.O. ID'}
                      style={{ width: '5rem' }}
                  />
                </Grid2>
                <Grid2>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                          history.push(`/resource/0`);
                      }}>
                      ADD
                  </Button>
                </Grid2>
                </Grid2>
            </div>
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    <>
                        <BasicTable
                            columns={columns}
                            handleSort={handleSort}
                            orderBy={orderBy}
                            direction={direction}
                            values={prettifyResources(resources)}
                            onClick={onClick}
                        />
                        <br />
                        <Grid2 container alignItems="flex-end">
                            <Grid2 size={{xs: 1, sm: 7}}></Grid2>
                            <Grid2 size={{xs: 11, sm: 5}}>
                                {total / MAX_PAGE < 1 ? null : (
                                    <PaginationControlled
                                        count={Math.ceil(total / MAX_PAGE)}
                                        page={page}
                                        onChange={handlePageChange}
                                    />
                                )}
                            </Grid2>
                        </Grid2>
                    </>
                )}
            </div>
        </Layout>
    );
};

ResourcesPage.displayName = 'ResourcesPage';

export default ResourcesPage;
