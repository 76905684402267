import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid2 } from '@mui/material';

import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';
import BasicTable from 'core/libs/core-ui/components/Table';
import SearchBox from 'core/libs/core-ui/components/SearchBox';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

import { useDebounce } from 'core/libs/core-ui/utils/useDebounce';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';

import { useExperiencer } from 'core/contexts/experiencer';

import { useStyles } from './styles';

const ExperiencersPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const { getExperiencers, experiencers, loading, total } = useExperiencer();

    const [multiSearch, setMultiSearch] = useState('');
    const multiSearchDebounce = useDebounce(multiSearch, 300);
    const [orderBy, setOrderBy] = useState('ci_id');
    const [direction, setDirection] = useState('desc');
    const [page, setPage] = useState(1);

    const MAX_PAGE = 20;

    useEffect(() => {
        getExperiencers({
            multiSearch: multiSearchDebounce,
            pager: {
                column: orderBy,
                direction,
                page,
                pageSize: MAX_PAGE,
            },
        });
    }, [orderBy, direction, page, multiSearchDebounce]);

    const prettifyExperiencers = (experiencers = []) => {
        return experiencers.map((experiencer) => {
            return {
                createdOnString: DateFormattedUtils(experiencer.createdOn),
                whatsappString: experiencer.notificationWhatsapp ? 'Yes' : 'No',
                ...experiencer,
            };
        });
    };
    const columns = [
        { align: 'left', val: 'id', label: 'Id', column: 'ci_id' },
        { align: 'left', val: 'userId', label: 'User Id', column: 'ci_user_id' },
        { val: 'name', label: 'Name', column: 'ci_name' },
        { val: 'email', label: 'Email', column: 'ci_email' },
        { val: 'mobilePhone', label: 'Mobile Phone', column: 'ci_mobile_phone' },
        { val: 'addressStreet', label: 'Address', column: 'ci_address_street' },
        { val: 'addressCity', label: 'City', column: 'ci_address_city' },
        { val: 'addressCountry', label: 'Country', column: 'ci_address_country' },
        { align: 'center', val: 'whatsappString', label: 'Whatsapp', column: 'ci_notification_whatsapp' },
        // { align: 'center', val: 'status', label: 'Status' },
    ];

    const handleSort = (column) => {
        if (orderBy === column) {
            setDirection(direction === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(column);
            setDirection('asc');
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const onChangeMultiSearch = (event) => {
        setMultiSearch(event.target.value);
    };

    const onClick = (event, id) => {
        history.push(`/experiencer/${id}`);
    };

    return (
        <Layout contentClassName={classes.content}>
            <Title value={'Tour Operators'} />
            <div style={{ marginBottom: '25px' }}>
                <Grid2
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <SearchBox
                        onChange={onChangeMultiSearch}
                        value={multiSearch}
                        label={'Search by Name or Email'}
                        style={{ width: '14rem', marginRight: '1rem' }}
                    />

                    {/* <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              history.push(`/host/0`)
            }}
            >ADD</Button> */}
                </Grid2>
            </div>
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    <>
                        <BasicTable
                            columns={columns}
                            handleSort={handleSort}
                            orderBy={orderBy}
                            direction={direction}
                            values={prettifyExperiencers(experiencers)}
                            onClick={onClick}
                        />
                        <br />
                        <Grid2 container alignItems="flex-end">
                            <Grid2 size={{xs: 1, sm: 7}}></Grid2>
                            <Grid2 size={{xs: 11, sm: 5}}>
                                {total / MAX_PAGE < 1 ? null : (
                                    <PaginationControlled
                                        count={Math.ceil(total / MAX_PAGE)}
                                        page={page}
                                        onChange={handlePageChange}
                                    />
                                )}
                            </Grid2>
                        </Grid2>
                    </>
                )}
            </div>
        </Layout>
    );
};

ExperiencersPage.displayName = 'ExperiencersPage';

export default ExperiencersPage;
