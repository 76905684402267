import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import { Typography, Grid2, useMediaQuery, Divider, Button, Card, CardContent, Chip, TextField, InputLabel } from '@mui/material';

import EditIcon from 'assets/icons/edit-white.svg';

import Layout from 'components/Layout';

import Title from 'core/libs/core-ui/components/Title';
import BasicTable from 'core/libs/core-ui/components/Table';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import Dialog from 'core/libs/core-ui/components/Dialog';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

import { useTranslation } from 'core/contexts/localization';
import { useBundle } from 'core/contexts/bundle';

import { useStyles } from './styles';
import { Delete, Edit } from '@mui/icons-material';
import SelectComponent from 'core/libs/core-ui/components/Input/Select';
import { usePrice } from 'core/contexts/price';

const BundlePage = () => {
    const classes = useStyles();
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { t: translate } = useTranslation();

    const { getBundle, setBundle, bundle, bundleExperiences, loading, updateBundle, addBundle, addBundleExperiences, deleteBundleExperiences, totalExperiences, getBundleExperience, updateBundleExperience } = useBundle();

    const [bundleChanged, setBundleChanged] = useState(false);
    const [bundleExpChanged, setBundleExpChanged] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [bundleInfo, setBundleInfo] = useState({});
    const [bundleData, setBundleData] = useState({});
    const [expToDelete, setExpToDelete] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [expToEdit, setExpToEdit] = useState(null);
    const [page, setPage] = useState(1);
    const [experience, setExperience] = useState({});
    const [expChanged, setExpChanged] = useState(false);
    const { priceGroup, getPriceGroup } = usePrice();
    const [experienceIndex, setExperienceIndex] = useState(0);

    const MAX_PAGE = 10;

    const locationParam = document.location.href.split('/');
    const bundleIndex = locationParam[locationParam.length - 1] ? parseInt(locationParam[locationParam.length - 1]) : false;

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const prettifyBundles = (bundle = []) => {
        return bundle.map((bundle) => {
            return {
                ...bundle,
                id: `${bundle.expId}`,
                name: `${bundle.name}`,
                experiencerName: `${bundle.experiencerName}`,
                duration: bundle.duration === null ? '' : `${bundle.duration}`,
                currency: bundle.currency === null ? '' : `${bundle.currency}`,
                priceGroupId: bundle.priceGroupId === null ? '' : `${bundle.priceGroupId}`,
                price: bundle.price === null ? '' : `${bundle.price}`,
                edit: (
                    <Edit
                        onClick={() => {
                            setExpToEdit(bundle);
                            setOpenEdit(true);
                            setExperienceIndex(bundle.expId);
                        }}
                        className={classes.tableButton}
                    />
                ),
                delete: (
                    <Delete
                        onClick={() => {
                            setExpToDelete(bundle.bundleExpId);
                            setOpenDelete(true);
                        }}
                        className={classes.tableButton}
                    />
                ),
            };
        });
    };

    const renderEditForm = () => {
        return (
            <Form
                onSubmit={onSubmitUpdateExperience}
                initialValues={expToEdit}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid2 container direction="column">
                            <Grid2 container direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                <Grid2>
                                    <h3 style={{ fontWeight: '700' }}>{expToEdit?.name || ''}</h3>
                                </Grid2>
                                <Grid2>
                                    <Button
                                        onClick={(e) => {
                                            form.reset({ duration: null, currency: null, price: null });
                                            setExpChanged(true);
                                        }}
                                        className={`${expToEdit?.duration ? classes.clearButton : classes.hidden}`}>
                                        Vaciar
                                    </Button>
                                </Grid2>
                            </Grid2>
                            <Grid2 style={{ width: '100%', margin: '.5rem 2px' }}>
                                <InputLabel>Duración:</InputLabel>
                                <Field name="duration">
                                    {({ input }) => (
                                        <TextField
                                            {...input}
                                            variant="outlined"
                                            onChange={(e) => {
                                                setExpChanged(true);
                                                let value = e.target.value < 0 ? 0 : e.target.value;
                                                if (value === '') value = null;
                                                setExperience({ ...experience, duration: value });
                                                input.onChange(value);
                                            }}
                                            type="number"
                                        />
                                    )}
                                </Field>
                            </Grid2>
                            <Grid2 style={{ width: '100%', margin: '.5rem 2px' }}>
                                <InputLabel>Tipo de Moneda:</InputLabel>
                                <Field
                                    name="currency"
                                    component={SelectComponent}
                                    values={[
                                        { name: null, id: null },
                                        { name: 'COP', id: 'COP' },
                                        { name: 'USD', id: 'USD' },
                                    ]}
                                    onChange={(value) => {
                                        let valueClone = value;
                                        if (valueClone.name === null) valueClone = null;
                                        experience.currency = valueClone;
                                        setExperience(experience);
                                        setExpChanged(true);
                                    }}
                                />
                            </Grid2>
                            <Grid2 style={{ width: '100%', margin: '.5rem 2px' }}>
                                <InputLabel>Precio:</InputLabel>
                                <Field name="price">
                                    {({ input }) => (
                                        <TextField
                                            {...input}
                                            variant="outlined"
                                            onChange={(e) => {
                                                setExpChanged(true);
                                                let value = e.target.value < 0 ? 0 : e.target.value;
                                                if (value === '') value = null;
                                                setExperience({ ...experience, price: value });
                                                input.onChange(value);
                                            }}
                                            type="number"
                                        />
                                    )}
                                </Field>
                            </Grid2>

                            <Grid2
                                container
                                direction="row"
                                style={{
                                    height: '50px',
                                    marginTop: '10px',
                                }}>
                                <Grid2>
                                    <Button
                                        onClick={(e) => {
                                            setOpenEdit(false);
                                            setExpChanged(false);
                                            return form.reset(e);
                                        }}
                                        className={classes.CancelButton}>
                                        Cancelar
                                    </Button>
                                </Grid2>
                                <Grid2>
                                    <Button
                                        onClick={(e) => {
                                            setExperience({});
                                            return handleSubmit(e);
                                        }}
                                        disabled={!expChanged}
                                        className={classes.SaveButton}>
                                        Guardar
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </form>
                )}
            />
        );
    };

    const functionAux = async (values) => {
        await updateBundleExperience(bundleData.id, expToEdit.bundleExpId, values);
        setOpenEdit(false);
        getBundle(bundleIndex);
        setExpChanged(false);
        getBundleExperience(bundleIndex, {
            pager: {
                page,
                pageSize: MAX_PAGE,
            },
        });
    };

    const onSubmitUpdateExperience = (values) => {
        if (bundleData.id && expToEdit) {
            const { duration, currency, price } = values;
            try {
                if (expChanged) {
                    if (duration || currency || price) {
                        if (!duration || !currency || !price) {
                            toast.error('Por favor, ingrese todos los campos', {
                                position: 'top-right',
                                theme: 'colored',
                            });
                            throw new Error('');
                        } else {
                            functionAux(values);
                        }
                    } else if (!duration || !currency || !price) {
                        if (duration || currency || price) {
                            toast.error('Por favor, limpiar todos los campos', {
                                position: 'top-right',
                                theme: 'colored',
                            });
                            throw new Error('');
                        } else {
                            functionAux(values);
                        }
                    }
                }
            } catch (err) {
                toast.error(err, {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
        }
    };

    const onSubmitDeleteExperiences = async () => {
        if (bundleData.id && expToDelete) {
            try {
                await deleteBundleExperiences(bundleData.id, expToDelete);
                setCanEdit(false);
                setExpToDelete(null);
                setOpenDelete(false);
                getBundle(bundleIndex);
                getBundleExperience(bundleIndex, {
                    pager: {
                        page,
                        pageSize: MAX_PAGE,
                    },
                });
            } catch (err) {
                toast.error(err, {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
        }
    };

    const onSubmitExperiences = async (values) => {
        const valuesClon = { ...values };
        if (bundleData.id && bundleExpChanged && valuesClon.experiences.length > 0) {
            try {
                const experiences = valuesClon.experiences.split(',');
                await addBundleExperiences(bundleData.id, { experiences });
                setCanEdit(false);
                getBundle(bundleIndex);
                getBundleExperience(bundleIndex, {
                    pager: {
                        page,
                        pageSize: MAX_PAGE,
                    },
                });
            } catch (err) {
                toast.error(err, {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
        }
    };

    const onSubmit = async (values) => {
        try {
            if (parseInt(bundleIndex) === 0) {
                const valuesClon = { ...values };

                const newBundle = await addBundle(valuesClon);
                history.push(`/bundle/${newBundle.id}`);
            } else {
                const valuesClon = { ...values };

                if (bundleChanged) {
                    await updateBundle(valuesClon);
                }
                setCanEdit(false);
                getBundle(bundleIndex);
                getBundleExperience(bundleIndex, {
                    pager: {
                        page,
                        pageSize: MAX_PAGE,
                    },
                });
            }
        } catch (err) {
            toast.error(err, {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };
    useEffect(() => {
        if (experienceIndex !== 0) {
            getPriceGroup(experienceIndex);
        }
    }, [experienceIndex]);

    useEffect(() => {
        setBundle({});
        if (bundleIndex === 0) {
            setCanEdit(true);
        } else {
            getBundle(bundleIndex);
            getBundleExperience(bundleIndex, {
                pager: {
                    page,
                    pageSize: MAX_PAGE,
                },
            });
        }
    }, [bundleIndex]);

    useEffect(() => {
        setBundleData(bundle);
        if (bundleIndex) {
            getBundleExperience(bundleIndex, {
                pager: {
                    page,
                    pageSize: MAX_PAGE,
                },
            });
        }
    }, [bundle]);

    useEffect(() => {
        if (bundleIndex) {
            getBundleExperience(bundleIndex, {
                pager: {
                    page,
                    pageSize: MAX_PAGE,
                },
            });
        }
    }, [page]);

    return (
        <Layout contentClassName={classes.content}>
            <Title value={'Bundle'} />
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    // Starts BUNDLE INFO
                    <Grid2>
                        <div className={classes.formContainer}>
                            <Title value={bundle.name} size={'l'} />
                            <Form
                                onSubmit={onSubmit}
                                initialValues={{
                                    ...bundleData,
                                }}
                                render={({ handleSubmit, form }) => (
                                    <form id="form" onSubmit={handleSubmit}>
                                        <div>
                                            <div className={classes.divEditButton}>
                                                <Typography style={{ fontWeight: 'bold', marginBottom: '10px' }} variant="h5" className={classes.text}>
                                                    {translate('bundle.edit_bundle_data')}
                                                </Typography>
                                                <Button
                                                    type="button"
                                                    onClick={() => {
                                                        setCanEdit(true);
                                                    }}
                                                    style={{ height: 40, margin: 'inherit' }}
                                                    className={classes.editButton}>
                                                    <img src={EditIcon} width={20} />
                                                    {isSmall ? null : <>&nbsp;</>}
                                                    {isSmall ? null : `${translate('bundle.edit')}`}
                                                </Button>
                                            </div>

                                            <Divider />

                                            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                <Grid2 container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={3}>
                                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                                        <Field id="id" name="id" component={TextInput} hintText={translate('bundle.id')} label={translate('bundle.id')} disabled />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                                        <Field
                                                            onFieldChange={(value) => {
                                                                bundleInfo.start = value;
                                                                setBundleInfo(bundleInfo);
                                                                setBundleChanged(true);
                                                            }}
                                                            name="start"
                                                            id="start"
                                                            component={TextInput}
                                                            hintText={translate('bundle.start')}
                                                            label={translate('bundle.start')}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2 container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={3}>
                                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                                        <Field
                                                            onFieldChange={(value) => {
                                                                bundleInfo.name = value;
                                                                setBundleInfo(bundleInfo);
                                                                setBundleChanged(true);
                                                            }}
                                                            id="name"
                                                            name="name"
                                                            component={TextInput}
                                                            hintText={translate('bundle.name')}
                                                            label={translate('bundle.name')}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                                        <Field
                                                            onFieldChange={(value) => {
                                                                bundleInfo.end = value;
                                                                setBundleInfo(bundleInfo);
                                                                setBundleChanged(true);
                                                            }}
                                                            name="end"
                                                            id="end"
                                                            component={TextInput}
                                                            hintText={translate('bundle.end')}
                                                            label={translate('bundle.end')}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2 container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={3}>
                                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                                        <Field
                                                            onFieldChange={(value) => {
                                                                bundleInfo.createdBy = value;
                                                                setBundleInfo(bundleInfo);
                                                                setBundleChanged(true);
                                                            }}
                                                            id="createdBy"
                                                            name="createdBy"
                                                            component={TextInput}
                                                            hintText={translate('bundle.createdBy')}
                                                            label={translate('bundle.createdBy')}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                                        <Field
                                                            onFieldChange={(value) => {
                                                                bundleInfo.createdOn = value;
                                                                setBundleInfo(bundleInfo);
                                                                setBundleChanged(true);
                                                            }}
                                                            id="createdOn"
                                                            name="createdOn"
                                                            component={TextInput}
                                                            hintText={translate('bundle.createdOn')}
                                                            label={translate('bundle.createdOn')}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2 container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={3}>
                                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                                        <Field
                                                            onFieldChange={(value) => {
                                                                bundleInfo.updatedBy = value;
                                                                setBundleInfo(bundleInfo);
                                                                setBundleChanged(true);
                                                            }}
                                                            id="updatedBy"
                                                            name="updatedBy"
                                                            component={TextInput}
                                                            hintText={translate('bundle.updatedBy')}
                                                            label={translate('bundle.updatedBy')}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                                        <Field
                                                            onFieldChange={(value) => {
                                                                bundleInfo.updatedOn = value;
                                                                setBundleInfo(bundleInfo);
                                                                setBundleChanged(true);
                                                            }}
                                                            id="updatedOn"
                                                            name="updatedOn"
                                                            component={TextInput}
                                                            hintText={translate('bundle.updatedOn')}
                                                            label={translate('bundle.updatedOn')}
                                                            disabled
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2 container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={3}>
                                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                                        <Field
                                                            onFieldChange={(value) => {
                                                                bundleInfo.status = value;
                                                                setBundleInfo(bundleInfo);
                                                                setBundleChanged(true);
                                                            }}
                                                            id="status"
                                                            name="status"
                                                            component={TextInput}
                                                            hintText={translate('bundle.status')}
                                                            label={translate('bundle.status')}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                                        <Field
                                                            onFieldChange={(value) => {
                                                                bundleInfo.ownerId = value;
                                                                setBundleInfo(bundleInfo);
                                                                setBundleChanged(true);
                                                            }}
                                                            id="ownerId"
                                                            name="ownerId"
                                                            component={TextInput}
                                                            hintText={translate('bundle.ownerId')}
                                                            label={translate('bundle.ownerId')}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2 container justifyContent={'center'} alignItems={'center'} spacing={3}>
                                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                                        <Field
                                                            style={{ marginBottom: '2em', width: '99%' }}
                                                            onFieldChange={(e) => {
                                                                bundleInfo.description = e.target.value;
                                                                setBundleInfo(bundleInfo);
                                                                setBundleChanged(true);
                                                            }}
                                                            id="description"
                                                            name="description"
                                                            component={'textarea'}
                                                            label={translate('bundle.description')}
                                                            disabled={!canEdit}
                                                            multiline
                                                            minRows={4}
                                                            // initialCharacters={bundle?.description?.length || 500}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                            </div>
                                        </div>

                                        <Grid2 container justify="flex-end" alignContent="flex-end">
                                            <Grid2 container justify={'space-between'} style={{ width: 'auto' }}>
                                                <Button
                                                    className="rounded mr-3 height-40 w-100px"
                                                    disabled={!canEdit}
                                                    type="button"
                                                    onClick={(e) => {
                                                        setCanEdit(false);
                                                        return form.reset(e);
                                                    }}>
                                                    {translate('btn_cancel')}
                                                </Button>

                                                <Button
                                                    onClick={(e) => {
                                                        setCanEdit(false);
                                                        return handleSubmit(e);
                                                    }}
                                                    className="rounded height-40 w-100px triper-button bg-green-300"
                                                    disabled={!canEdit}
                                                    type="submit">
                                                    {translate('btn_submit')}
                                                </Button>
                                            </Grid2>
                                        </Grid2>
                                    </form>
                                )}
                            />
                            <Grid2 container>
                                <Grid2 size={12}>
                                    {bundle.id ? (
                                        <>
                                            <Form
                                                onSubmit={onSubmitExperiences}
                                                initialValues={{
                                                    ...bundleExperiences.experiences,
                                                }}
                                                render={({ handleSubmit, form }) => (
                                                    <form id="form" onSubmit={handleSubmit}>
                                                        <Title size={'s'} value={translate('bundle.experiences')} />
                                                        <Grid2 container spacing={1}>
                                                            <Grid2 size={{ xs: 12, sm: 10 }}>
                                                                <Field
                                                                    onFieldChange={(value) => {
                                                                        bundleInfo.experiences = value;
                                                                        setBundleInfo(bundleInfo);
                                                                        setBundleExpChanged(true);
                                                                    }}
                                                                    id="experiences"
                                                                    name="experiences"
                                                                    component={TextInput}
                                                                    hintText={translate('bundle.experiencesHint')}
                                                                    label={translate('bundle.experiencesHint')}
                                                                    // disabled={!canEdit}
                                                                />
                                                            </Grid2>
                                                            <Grid2 size={{ xs: 2, sm: 1 }}>
                                                                <Button
                                                                    onClick={(e) => {
                                                                        // setCanEdit(false);
                                                                        return handleSubmit(e);
                                                                    }}
                                                                    className=""
                                                                    disabled={!bundleData.id}
                                                                    type="submit">
                                                                    {translate('btn_submit')}
                                                                </Button>
                                                            </Grid2>
                                                        </Grid2>
                                                    </form>
                                                )}
                                            />
                                            <br />
                                            <BasicTable
                                                columns={[
                                                    { align: 'left', val: 'id', label: 'Id' },
                                                    { val: 'name', label: 'Name' },
                                                    { val: 'experiencerName', label: 'Tour Operator' },
                                                    { val: 'duration', label: 'Duration' },
                                                    { val: 'currency', label: 'Currency' },
                                                    { val: 'price', label: 'Price' },
                                                    { val: 'edit', label: ' ', style: { width: '1px' } },
                                                    { val: 'delete', label: ' ', style: { width: '1px' } },
                                                ]}
                                                values={prettifyBundles(bundleExperiences)}
                                            />
                                            <br />
                                            <Grid2 container alignItems="flex-end">
                                                <Grid2 size={{ xs: 2, sm: 4 }}></Grid2>
                                                <Grid2 size={{ xs: 10, sm: 8 }}>{totalExperiences / MAX_PAGE < 1 ? null : <PaginationControlled count={Math.ceil(totalExperiences / MAX_PAGE)} page={page} onChange={handlePageChange} />}</Grid2>
                                            </Grid2>
                                            <Dialog
                                                open={openDelete}
                                                dialogTitle={'Attention!'}
                                                dialogContent={`Are you sure you want to remove the experience ${expToDelete}`}
                                                handleClose={() => {
                                                    setOpenDelete(false);
                                                    setExpToDelete(null);
                                                }}
                                                handleSubmit={onSubmitDeleteExperiences}
                                            />
                                            <Dialog
                                                open={openEdit}
                                                dialogContent={renderEditForm()}
                                                handleClose={() => {
                                                    setOpenEdit(false);
                                                    setExpToEdit(null);
                                                }}
                                                hideTitle
                                                hideBack
                                                hideSubmit
                                            />
                                        </>
                                    ) : null}
                                </Grid2>
                            </Grid2>
                        </div>
                    </Grid2>
                    // ENDS BUNDLE INFO
                )}
            </div>
        </Layout>
    );
};

BundlePage.displayName = 'BundlePage';

export default BundlePage;
