import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ComposedChart, Line, Bar, XAxis, YAxis, Label, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import Title from './Title';

// // Generate Booking Data
// function createData(time, amount) {
//   return { time, amount };
// }

// const data2 = [
//   createData('00:00', 0),
//   createData('03:00', 300),
//   createData('06:00', 600),
//   createData('09:00', 800),
//   createData('12:00', 1500),
//   createData('15:00', 2000),
//   createData('18:00', 2400),
//   createData('21:00', 2400),
//   createData('24:00', undefined),
// ];

export default function Chart({ todayData, totalBookings, totalTickets, ...props }) {
  const theme = useTheme();
  const data = todayData;


  const options = { month: "long" };
  //  console.log(new Intl.DateTimeFormat("en-US", options).format(valentines));

  return (
    <React.Fragment>
      <Title>{new Intl.DateTimeFormat("en-US", options).format(new Date())} - {props.name  || 'Total Concluded Tickets: '}</Title>
      <Typography>Total Bookings: {totalBookings} - Total Tickets {totalTickets}</Typography>

      { data ? 
        <ResponsiveContainer>
          <ComposedChart
            data={data}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
          >
            <XAxis dataKey="date" stroke={theme.palette.text.secondary} />
            <YAxis stroke={theme.palette.text.secondary}>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
              >
                Tickets
              </Label>
            </YAxis>
            <Tooltip />
            {/* <Legend /> */}
            {/* <Bar dataKey="DailyBookings" stackId="a" fill="#413ea0" /> */}
            {/* <Bar dataKey="DailyBookingsLastMonth" stackId="a" fill="#82ca9d" /> */}
            <Line type="monotone" dataKey={props.created ? "TotalCreatedTickets" : "TotalTickets"} stroke={props.created ? "#800080" : "#A6ba62"} dot={false} activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey={props.created ? "TotalCreatedTicketsLastMonth" : "TotalTicketsLastMonth"} stroke={props.created ? "#FF0000" : "#F2c35c"} dot={false} />
          </ComposedChart>
        </ResponsiveContainer>
         : '' 
      }
    </React.Fragment>
  );
}